import { Settings } from "../config/settings.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../core.module";
var utils = /** @class */ (function () {
    function utils(settings) {
        this.settings = settings;
        this.loadedScripts = {};
    }
    utils.isIterable = function (item) {
        return typeof item[Symbol.iterator] === 'function' || this.isFileList(item);
    };
    utils.isFileList = function (item) {
        return item instanceof FileList;
    };
    utils.strContains = function (haystack, needle) {
        if (!haystack || !needle)
            return false;
        needle = needle.toLowerCase();
        if (!Array.isArray(haystack)) {
            haystack = [haystack];
        }
        for (var i = 0; i < haystack.length; i++) {
            if (haystack[i].toLowerCase().indexOf(needle) > -1)
                return true;
        }
        return false;
    };
    /**
     * Convert "2018-02-05 13:51:1" into {date, time} object.
     */
    utils.splitDateTime = function (dateTime) {
        if (!dateTime)
            return { date: null, time: null };
        var split = dateTime.split(' ');
        return { date: split[0], time: split[1] };
    };
    /**
     * Get object property via dot notation string.
     */
    utils.getObjectProp = function (obj, prop) {
        if (!obj)
            return null;
        var arr = prop.split('.');
        while (arr.length && (obj = obj[arr.shift()]))
            ;
        return obj;
    };
    /**
     * Uppercase first letter of specified string.
     */
    utils.ucFirst = function (string) {
        return (string.charAt(0).toUpperCase() + string.slice(1));
    };
    /**
     * Flatten specified array of arrays.
     */
    utils.flattenArray = function (arrays) {
        return [].concat.apply([], arrays);
    };
    utils.moveArrayElement = function (array, from, to) {
        array.splice(to, 0, array.splice(from, 1)[0]);
    };
    /**
     * Slugify given string for use in urls.
     */
    utils.slugifyString = function (text) {
        if (!text)
            return text;
        return text.trim()
            .replace(/["']/g, '')
            .replace(/[^a-z0-9-]/gi, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '')
            .toLowerCase();
    };
    utils.poll = function (fn, timeout, interval) {
        if (timeout === void 0) { timeout = 2000; }
        if (interval === void 0) { interval = 100; }
        var endTime = Number(new Date()) + timeout;
        var checkCondition = function (resolve, reject) {
            //If the condition is met, we're done!
            var result = fn();
            if (result)
                resolve(result);
            else if (Number(new Date()) < endTime) {
                setTimeout(checkCondition, interval, resolve, reject);
            }
            else {
                reject(new Error('timed out for ' + fn));
            }
        };
        return new Promise(checkCondition);
    };
    utils.ngInjectableDef = i0.defineInjectable({ factory: function utils_Factory() { return new utils(i0.inject(i1.Settings)); }, token: utils, providedIn: i2.CoreModule });
    return utils;
}());
export { utils };
