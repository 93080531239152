/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./volume-controls.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i11 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i12 from "../../../../../vebto-client/core/config/settings.service";
import * as i13 from "./volume-controls.component";
import * as i14 from "../../player.service";
import * as i15 from "../../../../../vebto-client/core/services/browser-events.service";
var styles_VolumeControlsComponent = [i0.styles];
var RenderType_VolumeControlsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VolumeControlsComponent, data: {} });
export { RenderType_VolumeControlsComponent as RenderType_VolumeControlsComponent };
export function View_VolumeControlsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { progressTrack: 0 }), i1.ɵqud(402653184, 2, { progressHandle: 0 }), i1.ɵqud(402653184, 3, { outerTrack: 0 }), (_l()(), i1.ɵeld(3, 16777216, null, null, 4, "button", [["class", "player-action volume-button volume-on"], ["mat-icon-button", ""], ["matTooltip", "Mute"]], [[2, "visible", null], [8, "disabled", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.mute() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), i1.ɵdid(5, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "svg-icon", [["name", "volume-up"]], null, null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i1.ɵdid(7, 114688, null, 0, i11.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i12.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 4, "button", [["class", "player-action volume-button volume-off"], ["mat-icon-button", ""], ["matTooltip", "Unmute"]], [[2, "visible", null], [8, "disabled", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.unMute() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), i1.ɵdid(10, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "svg-icon", [["name", "volume-off"]], null, null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i1.ɵdid(12, 114688, null, 0, i11.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i12.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(13, 0, [[3, 0], ["outerTrack", 1]], null, 3, "div", [["class", "outer-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "inner-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["progressTrack", 1]], null, 0, "div", [["class", "progress-track"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, [[2, 0], ["progressHandle", 1]], null, 0, "div", [["class", "progress-handle"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "Mute"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "volume-up"; _ck(_v, 7, 0, currVal_3); var currVal_6 = "Unmute"; _ck(_v, 10, 0, currVal_6); var currVal_7 = "volume-off"; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.player.isMuted(); var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _co.player.isMuted(); var currVal_5 = (i1.ɵnov(_v, 9).disabled || null); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_8 = _co.player.isMuted(); _ck(_v, 15, 0, currVal_8); }); }
export function View_VolumeControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "volume-controls", [], null, null, null, View_VolumeControlsComponent_0, RenderType_VolumeControlsComponent)), i1.ɵdid(1, 4374528, null, 0, i13.VolumeControlsComponent, [i14.Player, i1.Renderer2, i1.NgZone, i15.BrowserEvents], null, null)], null, null); }
var VolumeControlsComponentNgFactory = i1.ɵccf("volume-controls", i13.VolumeControlsComponent, View_VolumeControlsComponent_Host_0, {}, {}, []);
export { VolumeControlsComponentNgFactory as VolumeControlsComponentNgFactory };
