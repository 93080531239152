import { MatDialog } from "@angular/material";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../core.module";
var Modal = /** @class */ (function () {
    function Modal(dialog) {
        this.dialog = dialog;
    }
    Modal.prototype.open = function (component, data, className) {
        return this.dialog.open(component, { panelClass: ['modal', className], data: data });
    };
    Modal.prototype.show = function (component, data) {
        return this.open(component, data);
    };
    Modal.ngInjectableDef = i0.defineInjectable({ factory: function Modal_Factory() { return new Modal(i0.inject(i1.MatDialog)); }, token: Modal, providedIn: i2.CoreModule });
    return Modal;
}());
export { Modal };
