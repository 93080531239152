import { FileValidator } from "./file-validator";
import { AppHttpClient } from "vebto-client/core/http/app-http-client.service";
import { utils } from "../services/utils";
import * as i0 from "@angular/core";
import * as i1 from "../http/app-http-client.service";
import * as i2 from "./file-validator";
import * as i3 from "./files.module";
var Uploads = /** @class */ (function () {
    /**
     * UploadsService Constructor.
     */
    function Uploads(httpClient, validator) {
        this.httpClient = httpClient;
        this.validator = validator;
    }
    /**
     * Get contents of specified file.
     */
    Uploads.prototype.getFileContents = function (file) {
        return this.httpClient.get('uploads/' + file.id);
    };
    /**
     * Download specified upload.
     */
    Uploads.prototype.downloadFile = function (file) {
        //Uploads.downloadFileFromUrl(this.httpClient.makeUrl('uploads/'+file.id+'/download'), file.name);
    };
    /**
     * Download file from specified url.
     */
    Uploads.downloadFileFromUrl = function (url, name) {
        if (name === void 0) { name = ''; }
        var link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    /**
     * Delete file upload matching given id.
     */
    Uploads.prototype.delete = function (ids) {
        return this.httpClient.delete('uploads', { ids: ids });
    };
    /**
     * Upload specified files as regular 'uploads'
     */
    Uploads.prototype.uploadFiles = function (files, params) {
        if (params === void 0) { params = {}; }
        if (!params.uri)
            params.uri = 'uploads';
        return this.upload(files, params.uri, params.httpParams);
    };
    /**
     * Upload files to specified uri.
     */
    Uploads.prototype.upload = function (files, uri, params) {
        if (params === void 0) { params = {}; }
        var data = new FormData();
        if (!utils.isIterable(files))
            files = [files];
        //append files
        for (var i = 0; i < files.length; i++) {
            data.append('files[]', files[i]);
        }
        //append extra params
        for (var name_1 in params) {
            data.append(name_1, params[name_1]);
        }
        return this.httpClient.postWithProgress(uri, data);
    };
    /**
     * Validate specified files.
     */
    Uploads.prototype.filesAreInvalid = function (fl, showErrors) {
        if (showErrors === void 0) { showErrors = false; }
        return this.validator.validateFiles(fl, showErrors);
    };
    /**
     * Open browser dialog for uploading files and
     * resolve promise with uploaded files.
     */
    Uploads.prototype.openUploadDialog = function (options) {
        if (options === void 0) { options = {}; }
        return new Promise(function (resolve, reject) {
            var input = Uploads.makeUploadInput(options);
            input.onchange = function (e) {
                resolve(e.target['files']);
                input.remove();
            };
            document.body.appendChild(input);
            input.click();
        });
    };
    /**
     * Create a html5 file upload input element.
     */
    Uploads.makeUploadInput = function (options) {
        if (options === void 0) { options = {}; }
        var input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = options['accept'];
        input.classList.add('hidden');
        input.id = 'hidden-file-upload-input';
        document.body.appendChild(input);
        return input;
    };
    Uploads.ngInjectableDef = i0.defineInjectable({ factory: function Uploads_Factory() { return new Uploads(i0.inject(i1.AppHttpClient), i0.inject(i2.FileValidator)); }, token: Uploads, providedIn: i3.FilesModule });
    return Uploads;
}());
export { Uploads };
