import { Router } from '@angular/router';
import { Users } from "../auth/users.service";
import { CurrentUser } from "vebto-client/auth/current-user";
import { ValueLists } from "../core/services/value-lists.service";
import { map } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { AuthService } from "../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/users.service";
import * as i2 from "@angular/router";
import * as i3 from "../auth/current-user";
import * as i4 from "../core/services/value-lists.service";
import * as i5 from "../auth/auth.service";
import * as i6 from "./account-settings.module";
var AccountSettingsResolve = /** @class */ (function () {
    function AccountSettingsResolve(users, router, currentUser, values, auth) {
        this.users = users;
        this.router = router;
        this.currentUser = currentUser;
        this.values = values;
        this.auth = auth;
    }
    AccountSettingsResolve.prototype.resolve = function (route) {
        var _this = this;
        return forkJoin(this.users.get(this.currentUser.get('id')).pipe(map(function (response) { return response.user; })), this.values.getValuesForSelects()).toPromise().then(function (response) {
            return { user: response[0], selects: response[1] };
        }, function () {
            _this.router.navigate([_this.auth.getRedirectUri()]);
            return null;
        });
    };
    AccountSettingsResolve.ngInjectableDef = i0.defineInjectable({ factory: function AccountSettingsResolve_Factory() { return new AccountSettingsResolve(i0.inject(i1.Users), i0.inject(i2.Router), i0.inject(i3.CurrentUser), i0.inject(i4.ValueLists), i0.inject(i5.AuthService)); }, token: AccountSettingsResolve, providedIn: i6.AccountSettingsModule });
    return AccountSettingsResolve;
}());
export { AccountSettingsResolve };
