var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { share, map } from 'rxjs/operators';
import { AppHttpClient } from "vebto-client/core/http/app-http-client.service";
import { FilterablePage } from "../../filterable-page/filterable-page";
import { Player } from "../../player/player.service";
var LibraryTracks = /** @class */ (function (_super) {
    __extends(LibraryTracks, _super);
    /**
     * LibraryTracks Service Constructor.
     */
    function LibraryTracks(http, player) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.player = player;
        /**
         * Ids of all tracks in user library.
         */
        _this.trackIds = [];
        /**
         * Current track order.
         */
        _this.order = 'date added';
        /**
         * Current replies infinite load page.
         */
        _this.currentPage = 1;
        /**
         * Last replies infinite load page.
         */
        _this.lastPage = 2;
        /**
         * Whether library tracks are being loaded currently.
         */
        _this.loading = false;
        /**
         * Whether at least one page of library tracks has already been fetched.
         */
        _this.alreadyFetched = false;
        _this.bindToPlaybackEndEvent();
        return _this;
    }
    /**
     * Get all currently loaded user library tracks.
     */
    LibraryTracks.prototype.get = function () {
        return this.filteredItems;
    };
    /**
     * Check if user has specified track in library.
     */
    LibraryTracks.prototype.has = function (track) {
        return this.trackIds.indexOf(track.id) > -1;
    };
    /**
     * Sort tracks using specified order.
     */
    LibraryTracks.prototype.sort = function (order) {
        var _this = this;
        this.order = order;
        this.loadTracks({ page: 1 }).subscribe(function (response) {
            _this.setPaginationResponse(response);
        });
    };
    /**
     * Add specified tracks to library.
     */
    LibraryTracks.prototype.add = function (tracks) {
        var _this = this;
        if (!Array.isArray(tracks))
            tracks = [tracks];
        var ids = tracks.map(function (track) { return track.id; });
        this.http.post('user/library/tracks/add', { ids: ids }).subscribe(function () {
            _this.prependItems(tracks);
            _this.trackIds = _this.trackIds.concat(ids);
        }, function () { });
    };
    /**
     * Remove specified tracks from library.
     */
    LibraryTracks.prototype.remove = function (tracks) {
        var _this = this;
        if (!Array.isArray(tracks))
            tracks = [tracks];
        var ids = tracks.map(function (track) { return track.id; });
        this.http.post('user/library/tracks/remove', { ids: ids }).subscribe(function () {
            _this.removeItems(tracks);
            tracks.forEach(function (track) {
                _this.trackIds.splice(_this.trackIds.findIndex(function (id) { return track.id === id; }), 1);
            });
        }, function () { });
    };
    /**
     * Check if more tracks can be loaded via infinite load.
     */
    LibraryTracks.prototype.canLoadMoreTracks = function () {
        return !this.loading && !this.filterQuery.value && this.currentPage < this.lastPage;
    };
    /**
     * Load next infinite load page.
     */
    LibraryTracks.prototype.loadMore = function () {
        var _this = this;
        this.loading = true;
        var request = this.loadTracks({ page: this.currentPage + 1 });
        request.subscribe(function (response) {
            _this.appendItems(response.data);
        });
        return request;
    };
    /**
     * Set ids of all tracks in user's library.
     */
    LibraryTracks.prototype.setTrackIds = function (ids) {
        if (!ids)
            ids = [];
        this.trackIds = ids.slice();
    };
    /**
     * Reset user library tracks service to initial state.
     */
    LibraryTracks.prototype.reset = function () {
        this.trackIds = [];
        this.order = 'date added';
        this.currentPage = 1;
        this.lastPage = 2;
        this.loading = false;
        this.alreadyFetched = false;
    };
    /**
     * Set specified tracks and sync with player queue.
     */
    LibraryTracks.prototype.setItems = function (tracks) {
        _super.prototype.setItems.call(this, tracks);
        this.player.overrideQueue({ tracks: tracks });
    };
    /**
     * append specified tracks and sync with player queue.
     */
    LibraryTracks.prototype.appendItems = function (tracks) {
        _super.prototype.appendItems.call(this, tracks);
        this.player.getQueue().append(tracks);
    };
    /**
     * Set specified filtered tracks (via search) and sync with player queue.
     */
    LibraryTracks.prototype.setFilteredItems = function (tracks) {
        _super.prototype.setFilteredItems.call(this, tracks);
        this.player.overrideQueue({ tracks: tracks });
    };
    /**
     * Filter library tracks by specified query.
     */
    LibraryTracks.prototype.filter = function (query) {
        return this.fetch({ query: query }).pipe(map(function (response) { return response.data; }));
    };
    /**
     * Load tracks based on specified params.
     */
    LibraryTracks.prototype.loadTracks = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        this.loading = true;
        params.order = this.order.replace(' ', '_');
        var request = this.fetch(params).pipe(share());
        request.subscribe(function (response) {
            _this.currentPage = response.current_page;
            _this.lastPage = response.last_page;
            _this.loading = false;
        }, function () { });
        return request;
    };
    /**
     * Get tracks in user's library.
     */
    LibraryTracks.prototype.fetch = function (params) {
        if (params === void 0) { params = { page: 1 }; }
        this.alreadyFetched = true;
        return this.http.get('user/library/tracks', params);
    };
    /**
     * init or reset the service using specified pagination response.
     */
    LibraryTracks.prototype.setPaginationResponse = function (response) {
        if (!response)
            return;
        this.currentPage = response.current_page;
        this.lastPage = response.last_page;
        this.setItems(response.data);
    };
    /**
     * Lazy load more tracks from user library for
     * continuous playback after current track ends.
     */
    LibraryTracks.prototype.bindToPlaybackEndEvent = function () {
        var _this = this;
        this.player.state.onChange$.subscribe(function (type) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (type !== 'PLAYBACK_ENDED')
                            return [2 /*return*/];
                        //if player is not playing user library currently, bail
                        if (!this.player.queue.itemIsQueued('library-tracks'))
                            return [2 /*return*/];
                        if (!(this.player.getQueue().isLast() && this.canLoadMoreTracks())) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadMore().toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.player.playNext();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return LibraryTracks;
}(FilterablePage));
export { LibraryTracks };
