/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../vebto-client/core/translations/translate.directive";
import * as i4 from "../../../../vebto-client/core/translations/translations.service";
import * as i5 from "../../../../vebto-client/core/config/settings.service";
import * as i6 from "../../context-menu/context-menu-playlist-panel/context-menu-playlist-panel.component.ngfactory";
import * as i7 from "../../context-menu/context-menu-playlist-panel/context-menu-playlist-panel.component";
import * as i8 from "../user-playlists.service";
import * as i9 from "../playlists.service";
import * as i10 from "../../../../vebto-client/core/ui/context-menu/context-menu.service";
import * as i11 from "../../../../vebto-client/core/ui/modal.service";
import * as i12 from "../../../../vebto-client/auth/current-user";
import * as i13 from "../../../../vebto-client/core/ui/toast.service";
import * as i14 from "./playlist-context-menu.component";
var styles_PlaylistContextMenuComponent = [];
var RenderType_PlaylistContextMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlaylistContextMenuComponent, data: {} });
export { RenderType_PlaylistContextMenuComponent as RenderType_PlaylistContextMenuComponent };
function View_PlaylistContextMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["class", "primary-name hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "a", [["class", "secondary-name hover-underline"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i0.ɵdid(9, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["By"])), (_l()(), i0.ɵted(11, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.playlist(_co.data.item); _ck(_v, 4, 0, currVal_3); var currVal_7 = _co.urls.user(_co.data.item.editors[0]); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImage(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).target; var currVal_2 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = _co.data.item.name; _ck(_v, 5, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 7).target; var currVal_6 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_8 = _co.data.item.editors[0]["display_name"]; _ck(_v, 11, 0, currVal_8); }); }
function View_PlaylistContextMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.follow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Follow"]))], null, null); }
function View_PlaylistContextMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unfollow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Unfollow"]))], null, null); }
function View_PlaylistContextMenuComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEditModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Edit"]))], null, null); }
function View_PlaylistContextMenuComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.maybeDeletePlaylist() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Delete"]))], null, null); }
function View_PlaylistContextMenuComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makePublic() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Make Public"]))], null, null); }
function View_PlaylistContextMenuComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.makePrivate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Make Private"]))], null, null); }
function View_PlaylistContextMenuComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openShareModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Share"]))], null, null); }
function View_PlaylistContextMenuComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "context-menu-playlist-panel", [["class", "context-menu-panel"]], null, [[null, "close$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close$" === en)) {
        var pd_0 = (_co.openPanel("primary") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ContextMenuPlaylistPanelComponent_0, i6.RenderType_ContextMenuPlaylistPanelComponent)), i0.ɵdid(1, 49152, null, 0, i7.ContextMenuPlaylistPanelComponent, [i8.UserPlaylists, i9.Playlists, i10.ContextMenu, i11.Modal, i12.CurrentUser, i1.Router, i13.Toast], { tracks: [0, "tracks"] }, { close$: "close$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTracks(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlaylistContextMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 18, "div", [["class", "context-menu-panel primary-panel"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_4)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_5)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_6)), i0.ɵdid(13, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_7)), i0.ɵdid(15, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 2, "div", [["class", "menu-item"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyLinkToClipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(17, 4341760, null, 0, i3.TranslateDirective, [i0.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i0.ɵted(-1, null, ["Copy Link"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_8)), i0.ɵdid(20, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlaylistContextMenuComponent_9)), i0.ɵdid(22, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImage(); _ck(_v, 1, 0, currVal_0); var currVal_2 = (!_co.userIsCreator() && !_co.userIsFollowing()); _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.userIsCreator() && _co.userIsFollowing()); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.userIsCreator(); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.userIsCreator(); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.userIsCreator() && !_co.isPublic()); _ck(_v, 13, 0, currVal_6); var currVal_7 = (_co.userIsCreator() && _co.isPublic()); _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.isPublic(); _ck(_v, 20, 0, currVal_8); var currVal_9 = _co.activePanelIs("playlist"); _ck(_v, 22, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.activePanelIs("primary"); _ck(_v, 2, 0, currVal_1); }); }
export function View_PlaylistContextMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "playlist-context-menu", [["class", "context-menu"]], null, null, null, View_PlaylistContextMenuComponent_0, RenderType_PlaylistContextMenuComponent)), i0.ɵdid(1, 49152, null, 0, i14.PlaylistContextMenuComponent, [i0.Injector, i9.Playlists, i8.UserPlaylists], null, null)], null, null); }
var PlaylistContextMenuComponentNgFactory = i0.ɵccf("playlist-context-menu", i14.PlaylistContextMenuComponent, View_PlaylistContextMenuComponent_Host_0, {}, {}, []);
export { PlaylistContextMenuComponentNgFactory as PlaylistContextMenuComponentNgFactory };
