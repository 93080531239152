import { ElementRef, QueryList } from '@angular/core';
import { Translations } from "vebto-client/core/translations/translations.service";
var SortingHeaderComponent = /** @class */ (function () {
    /**
     * SortingHeaderComponent
     */
    function SortingHeaderComponent(i18n) {
        this.i18n = i18n;
    }
    Object.defineProperty(SortingHeaderComponent.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = this.i18n.t(value);
        },
        enumerable: true,
        configurable: true
    });
    return SortingHeaderComponent;
}());
export { SortingHeaderComponent };
