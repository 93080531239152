import { MatSnackBar } from "@angular/material";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "./files.module";
var FileValidator = /** @class */ (function () {
    /**
     * FileValidator Constructor.
     */
    function FileValidator(toast) {
        this.toast = toast;
        /**
         * Rules that files under validation must pass.
         */
        this.rules = {
            maxSize: 50000 * 1000,
            maxFiles: 5,
            blacklist: [],
            whitelist: [],
        };
    }
    /**
     * Validate specified files and optionally show error messages in toast window..
     */
    FileValidator.prototype.validateFiles = function (fs, showErrors) {
        if (showErrors === void 0) { showErrors = false; }
        var errors = {};
        if (fs.length > this.rules.maxFiles) {
            errors['*'] = 'you can upload a maximum of ' + this.rules.maxFiles + ' files.';
        }
        for (var i = 0; i < fs.length; i++) {
            var fileErrors = this.validateFile(fs[i]);
            if (fileErrors)
                errors[fs[i].name] = fileErrors;
        }
        var hasErrors = Object.keys(errors).length;
        if (showErrors && hasErrors) {
            this.toast.open(errors[Object.keys(errors)[0]]);
        }
        return hasErrors ? errors : null;
    };
    /**
     * Validate specified file against validation rules and return errors.
     */
    FileValidator.prototype.validateFile = function (file) {
        var errors = [];
        var extension = FileValidator.getFileNameAndExtension(file.name).extension;
        if (file.size > this.rules.maxSize) {
            errors.push(file.name + ' is to large. Maximum file size is ' + this.rules.maxSize);
        }
        if (this.rules.blacklist.indexOf(extension) > -1) {
            errors.push(file.name + ' type is invalid. ' + extension + ' files are not allowed.');
        }
        if (this.rules.whitelist.length && this.rules.whitelist.indexOf(extension) === -1) {
            errors.push(file.name + ' type is invalid. Only ' + this.rules.whitelist.join(', ') + ' extensions are allowed.');
        }
        return errors.length ? errors : null;
    };
    FileValidator.getFileNameAndExtension = function (fullFileName) {
        var re = /(?:\.([^.]+))?$/;
        var fileExt = re.exec(fullFileName)[1];
        var fileName = fullFileName.replace('.' + fileExt, '');
        return {
            name: fileName,
            extension: fileExt
        };
    };
    FileValidator.getFileType = function (file) {
        var extensionsToMime = {
            'md': 'text/markdown',
            'markdown': 'text/markdown',
            'mp4': 'video/mp4',
            'mp3': 'audio/mp3',
            'svg': 'image/svg+xml',
            'jpg': 'image/jpeg',
            'png': 'image/png',
            'gif': 'image/gif',
            'yaml': 'text/yaml',
            'yml': 'text/yaml'
        };
        var fileExtension = file.name ? FileValidator.getFileNameAndExtension(file.name).extension : null;
        //check if mime type is set in the file object
        if (file.type) {
            return file.type;
        }
        //see if we can map extension to a mime type
        if (fileExtension && extensionsToMime[fileExtension]) {
            return extensionsToMime[fileExtension];
        }
        return null;
    };
    FileValidator.ngInjectableDef = i0.defineInjectable({ factory: function FileValidator_Factory() { return new FileValidator(i0.inject(i1.MatSnackBar)); }, token: FileValidator, providedIn: i2.FilesModule });
    return FileValidator;
}());
export { FileValidator };
