var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NgZone } from '@angular/core';
import { PlayerQueue } from "../player-queue.service";
import { Search } from "../../search/search.service";
import { PlayerState } from "../player-state.service";
import { Settings } from "vebto-client/core/config/settings.service";
import * as Dot from "dot-object";
import { LazyLoaderService } from "vebto-client/core/utils/lazy-loader.service";
var YoutubeStrategy = /** @class */ (function () {
    /**
     * YoutubeStrategy Constructor.
     */
    function YoutubeStrategy(queue, state, search, zone, settings, lazyLoader) {
        this.queue = queue;
        this.state = state;
        this.search = search;
        this.zone = zone;
        this.settings = settings;
        this.lazyLoader = lazyLoader;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after youtube player is bootstrapped.
         */
        this.pendingVolume = null;
        /**
         * Results of last youtube search for currently cued video.
         */
        this.searchResults = [];
        /**
         * Number of tracks skipped in queue due to youtube iframe or search error.
         */
        this.numberOfTracksSkipped = 0;
    }
    /**
     * Load current queue item and play youtube video.
     */
    YoutubeStrategy.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cueTrack(this.queue.getCurrent())];
                    case 1:
                        _a.sent();
                        this.youtube.playVideo();
                        this.state.playing = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause youtube video.
     */
    YoutubeStrategy.prototype.pause = function () {
        this.youtube.pauseVideo();
        this.state.playing = false;
    };
    /**
     * stop youtube video.
     */
    YoutubeStrategy.prototype.stop = function () {
        this.youtube.stopVideo();
        this.state.playing = false;
    };
    /**
     * Seek to specified time in youtube video.
     */
    YoutubeStrategy.prototype.seekTo = function (time) {
        this.youtube.seekTo(time, true);
    };
    /**
     * Get loaded youtube video duration in seconds.
     */
    YoutubeStrategy.prototype.getDuration = function () {
        return this.youtube.getDuration() || 0;
    };
    /**
     * Get elapsed time in seconds since the video started playing
     */
    YoutubeStrategy.prototype.getCurrentTime = function () {
        return this.youtube.getCurrentTime();
    };
    /**
     * Set youtube player volume.
     */
    YoutubeStrategy.prototype.setVolume = function (number) {
        if (!this.youtube || !this.youtube.setVolume) {
            this.pendingVolume = number;
        }
        else {
            this.youtube.setVolume(number);
        }
    };
    /**
     * Mute youtube player.
     */
    YoutubeStrategy.prototype.mute = function () {
        this.youtube && this.youtube.mute && this.youtube.mute();
    };
    /**
     * Unmute youtube player.
     */
    YoutubeStrategy.prototype.unMute = function () {
        this.youtube && this.youtube.unMute && this.youtube.unMute();
    };
    /**
     * Get track that is currently cued for playback.
     */
    YoutubeStrategy.prototype.getCuedTrack = function () {
        return this.cuedTrack;
    };
    /**
     * Check if youtube player is ready.
     */
    YoutubeStrategy.prototype.ready = function () {
        return this.bootstrapped;
    };
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    YoutubeStrategy.prototype.cueTrack = function (track) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var artist, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.cueing === track || this.cuedTrack === track)
                            return [2 /*return*/];
                        this.cueing = track;
                        //clear search results, so old search results are not used for new track
                        this.searchResults = [];
                        this.state.buffering = true;
                        if (!!track.youtube_id) return [3 /*break*/, 2];
                        artist = Dot.pick('album.artist.name', track) || track.artists[0];
                        _a = this;
                        return [4 /*yield*/, this.search.videoId(artist, track.name).toPromise().catch(function () { })];
                    case 1:
                        _a.searchResults = (_b.sent());
                        this.assignFirstSearchResult(track);
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.bootstrap(track.youtube_id).then(function () {
                            _this.cueYoutubeVideo(track);
                        })];
                }
            });
        });
    };
    /**
     * Destroy youtube playback strategy.
     */
    YoutubeStrategy.prototype.destroy = function () {
        try {
            this.youtube && this.youtube.destroy();
        }
        catch (e) { }
        this.youtube = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
        this.searchResults = [];
    };
    /**
     * Set specified player state.
     */
    YoutubeStrategy.prototype.setState = function (name, value) {
        var _this = this;
        this.zone.run(function () { return _this.state[name] = value; });
    };
    /**
     * Cue specified youtube video for specified track.
     */
    YoutubeStrategy.prototype.cueYoutubeVideo = function (track) {
        if (track.youtube_id !== this.getYoutubeId()) {
            var suggestedQuality = this.settings.get('youtube.suggested_quality');
            this.youtube.cueVideoById({ videoId: track.youtube_id, suggestedQuality: suggestedQuality });
        }
        this.cuedTrack = track;
        this.cueing = null;
    };
    /**
     * Get currently cued youtube video ID .
     */
    YoutubeStrategy.prototype.getYoutubeId = function () {
        var url = this.youtube.getVideoUrl();
        return url && url.split('v=')[1];
    };
    /**
     * Assign first search result youtube id to track and shift the search array.
     */
    YoutubeStrategy.prototype.assignFirstSearchResult = function (track) {
        if (this.searchResults && this.searchResults.length) {
            track.youtube_id = this.searchResults[0].id;
            this.searchResults.shift();
        }
        return track;
    };
    /**
     * Try to play fallback videos from last youtube search.
     */
    YoutubeStrategy.prototype.tryToPlayFallbackVideos = function (e) {
        if (!this.searchResults.length) {
            return this.handleYoutubeError();
        }
        this.assignFirstSearchResult(this.cuedTrack);
        this.cueYoutubeVideo(this.cuedTrack);
        this.youtube.playVideo();
    };
    /**
     * Handle youtube search or iframe embed error.
     */
    YoutubeStrategy.prototype.handleYoutubeError = function () {
        this.cuedTrack = null;
        this.setState('playing', false);
        this.numberOfTracksSkipped++;
        //if we have skipped more then 5 tracks, we can assume
        //that there's a critical issue with youtube search or
        //embed so we should stop the playback and bail.
        if (this.numberOfTracksSkipped <= 5) {
            this.state.firePlaybackEnded();
        }
        return;
    };
    /**
     * Bootstrap youtube playback strategy.
     */
    YoutubeStrategy.prototype.bootstrap = function (videoId) {
        var _this = this;
        if (this.bootstrapped)
            return new Promise(function (resolve) { return resolve(); });
        if (this.bootstrapping)
            return this.bootstrapping;
        this.lazyLoader.loadScript('https://www.youtube.com/iframe_api');
        this.bootstrapping = new Promise(function (resolve) {
            if (window['onYouTubeIframeAPIReady']) {
                return _this.initYoutubePlayer(videoId, resolve);
            }
            else {
                window['onYouTubeIframeAPIReady'] = function () {
                    _this.initYoutubePlayer(videoId, resolve);
                };
            }
        });
        return this.bootstrapping;
    };
    /**
     * Initiate youtube iframe player.
     */
    YoutubeStrategy.prototype.initYoutubePlayer = function (videoId, resolve) {
        var _this = this;
        this.youtube = new YT.Player('youtube-player', {
            videoId: videoId,
            playerVars: this.getPlayerVars(),
            events: {
                onReady: function () { return _this.onPlayerReady(resolve); },
                onError: this.tryToPlayFallbackVideos.bind(this),
                onStateChange: this.onYoutubePlayerStateChange.bind(this)
            }
        });
    };
    /**
     * Handle youtube player ready event.
     */
    YoutubeStrategy.prototype.onPlayerReady = function (resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        this.bootstrapping = null;
        resolve();
        this.state.fireReadyEvent();
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    };
    /**
     * Handle youtube player state changes.
     */
    YoutubeStrategy.prototype.onYoutubePlayerStateChange = function (e) {
        switch (e.data) {
            case YT.PlayerState.ENDED:
                this.state.firePlaybackEnded();
                this.setState('playing', false);
                break;
            case YT.PlayerState.PLAYING:
                this.numberOfTracksSkipped = 0;
                this.setState('playing', true);
                break;
            case YT.PlayerState.PAUSED:
                this.setState('playing', false);
                break;
        }
    };
    /**
     * Get youtube player vars.
     */
    YoutubeStrategy.prototype.getPlayerVars = function () {
        return {
            autoplay: 0,
            rel: 0,
            showinfo: 0,
            disablekb: 1,
            fs: 0,
            controls: 0,
            modestbranding: 1,
            iv_load_policy: 3,
            playsinline: 1,
        };
    };
    return YoutubeStrategy;
}());
export { YoutubeStrategy };
