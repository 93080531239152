import { map } from "rxjs/operators";
import { AppHttpClient } from "vebto-client/core/http/app-http-client.service";
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
import * as i2 from "./auth.module";
var Users = /** @class */ (function () {
    /**
     * Users REST service constructor.
     */
    function Users(http) {
        this.http = http;
    }
    /**
     * Get user matching specified ID.
     */
    Users.prototype.get = function (id, params) {
        return this.http.get("users/" + id, params);
    };
    /**
     * Fetch users without pagination.
     */
    Users.prototype.getAll = function (params) {
        if (params === void 0) { params = null; }
        return this.http.get('users', params).pipe(map(function (response) { return response['data']; }));
    };
    /**
     * Create a new user.
     */
    Users.prototype.create = function (payload) {
        return this.http.post('users', payload);
    };
    /**
     * Update existing user.
     */
    Users.prototype.update = function (id, payload) {
        return this.http.put('users/' + id, payload);
    };
    /**
     * Change specified user password.
     */
    Users.prototype.changePassword = function (id, payload) {
        return this.http.post('users/' + id + '/password/change', payload);
    };
    /**
     * Attach specified groups to user.
     */
    Users.prototype.attachGroups = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/groups/attach', payload);
    };
    /**
     * Detach specified groups from user.
     */
    Users.prototype.detachGroups = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/groups/detach', payload);
    };
    /**
     * Add specified permissions to user.
     */
    Users.prototype.addPermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/add', payload);
    };
    /**
     * Remove specified permissions from user.
     */
    Users.prototype.removePermissions = function (id, payload) {
        if (payload === void 0) { payload = {}; }
        return this.http.post('users/' + id + '/permissions/remove', payload);
    };
    /**
     * Upload and attach avatar to specified user.
     */
    Users.prototype.uploadAvatar = function (id, files) {
        var payload = new FormData();
        payload.append('avatar', files.item(0));
        return this.http.post('users/' + id + '/avatar', payload);
    };
    /**
     * Delete specified user's avatar.
     */
    Users.prototype.deleteAvatar = function (id) {
        return this.http.delete('users/' + id + '/avatar');
    };
    /**
     * Delete multiple users.
     */
    Users.prototype.deleteMultiple = function (ids) {
        return this.http.delete('users/delete-multiple', { ids: ids });
    };
    Users.ngInjectableDef = i0.defineInjectable({ factory: function Users_Factory() { return new Users(i0.inject(i1.AppHttpClient)); }, token: Users, providedIn: i2.AuthModule });
    return Users;
}());
export { Users };
