/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./queue-sidebar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lazy-load.directive";
import * as i3 from "../player/playback-control-button/playback-control-button.component.ngfactory";
import * as i4 from "../player/playback-control-button/playback-control-button.component";
import * as i5 from "../player/player.service";
import * as i6 from "../../../vebto-client/core/ui/context-menu/context-menu.directive";
import * as i7 from "../../../vebto-client/core/ui/context-menu/context-menu.service";
import * as i8 from "../../../vebto-client/core/ui/ui.module";
import * as i9 from "@angular/common";
import * as i10 from "../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i11 from "../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i12 from "../../../vebto-client/core/config/settings.service";
import * as i13 from "../../../vebto-client/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i14 from "../../../vebto-client/core/ui/breakpoints.service";
import * as i15 from "@angular/cdk/observers";
import * as i16 from "../../../vebto-client/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i17 from "./queue-sidebar.component";
import * as i18 from "../player/player-queue.service";
import * as i19 from "./queue-sidebar.service";
import * as i20 from "../web-player-images.service";
var styles_QueueSidebarComponent = [i0.styles];
var RenderType_QueueSidebarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_QueueSidebarComponent, data: {} });
export { RenderType_QueueSidebarComponent as RenderType_QueueSidebarComponent };
function View_QueueSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["class", "lazy-loaded"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(2, 4210688, null, 0, i2.LazyLoadDirective, [i1.ElementRef], { scrollTarget: [0, "scrollTarget"], imgUrl: [1, "imgUrl"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "playback-control-button", [], null, [[null, "play"], [null, "pause"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("play" === en)) {
        var pd_0 = (_co.playTrack(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("pause" === en)) {
        var pd_1 = (_co.player.pause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PlaybackControlButtonComponent_0, i3.RenderType_PlaybackControlButtonComponent)), i1.ɵdid(4, 49152, null, 0, i4.PlaybackControlButtonComponent, [i5.Player], { track: [0, "track"] }, { play: "play", pause: "pause" })], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent.parent, 0); var currVal_2 = _v.parent.context.$implicit.album.image; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wpImages.getDefault("album"); _ck(_v, 1, 0, currVal_0); }); }
function View_QueueSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "queue-item"]], [[2, "active", null]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.ContextMenuDirective, [i1.ElementRef, i7.ContextMenu, i8.AVAILABLE_CONTEXT_MENUS], { params: [0, "params"] }, null), i1.ɵpod(2, { item: 0, type: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueueSidebarComponent_2)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "meta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "track-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "artist-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "no-style toggle-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showContextMenu(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "svg-icon", [["name", "more-horiz"]], null, null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i1.ɵdid(12, 114688, null, 0, i11.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i12.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit, "track"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.album; _ck(_v, 4, 0, currVal_2); var currVal_5 = "more-horiz"; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trackIsPlaying(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.artists; _ck(_v, 9, 0, currVal_4); }); }
export function View_QueueSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["scrollContainer", 1]], null, 3, "div", [["class", "scroll-container"], ["customScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i13.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i14.BreakpointsService, i1.NgZone, i15.MutationObserverFactory, i12.Settings, i16.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueueSidebarComponent_1)), i1.ɵdid(3, 802816, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.queue.getAll(); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_QueueSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "queue-sidebar", [], [[2, "hide", null]], null, null, View_QueueSidebarComponent_0, RenderType_QueueSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i17.QueueSidebarComponent, [i18.PlayerQueue, i5.Player, i19.QueueSidebar, i7.ContextMenu, i12.Settings, i20.WebPlayerImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).sidebar.isVisible(); _ck(_v, 0, 0, currVal_0); }); }
var QueueSidebarComponentNgFactory = i1.ɵccf("queue-sidebar", i17.QueueSidebarComponent, View_QueueSidebarComponent_Host_0, {}, {}, []);
export { QueueSidebarComponentNgFactory as QueueSidebarComponentNgFactory };
