/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../translations/translate.directive";
import * as i3 from "../../translations/translations.service";
import * as i4 from "../../config/settings.service";
import * as i5 from "./not-found-page.component";
var styles_NotFoundPageComponent = [i0.styles];
var RenderType_NotFoundPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NotFoundPageComponent, data: {} });
export { RenderType_NotFoundPageComponent as RenderType_NotFoundPageComponent };
export function View_NotFoundPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["404 Page Not Found"]))], null, null); }
export function View_NotFoundPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found-page", [], null, null, null, View_NotFoundPageComponent_0, RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i5.NotFoundPageComponent, [], null, null)], null, null); }
var NotFoundPageComponentNgFactory = i1.ɵccf("not-found-page", i5.NotFoundPageComponent, View_NotFoundPageComponent_Host_0, {}, {}, []);
export { NotFoundPageComponentNgFactory as NotFoundPageComponentNgFactory };
