<button class="previous-track" mat-icon-button (click)="player.playPrevious()" [disabled]="!player.ready()">
  <svg-icon name="skip-previous"></svg-icon>
</button>

<button class="play-track" mat-icon-button [disabled]="!player.ready()" [class.hidden]="player.isPlaying() || player.isBuffering()" (click)="player.play()">
  <svg-icon name="play-arrow"></svg-icon>
</button>

<button class="pause-track" mat-icon-button [disabled]="!player.ready()" [class.hidden]="!player.isPlaying()" (click)="player.pause()">
  <svg-icon name="pause"></svg-icon>
</button>

<div class="buffering" [class.hidden]="!player.isBuffering()"><div class="spinner"></div></div>

<button class="next-track" mat-icon-button (click)="player.playNext()" [disabled]="!player.ready()">
  <svg-icon name="skip-next"></svg-icon>
</button>