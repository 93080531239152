<ng-container *ngIf="currentUser.isLoggedIn()">
    <div class="menu-trigger" [matMenuTriggerFor]="menu">
        <img class="avatar" src="{{currentUser.get('avatar')}}">

        <div class="email">{{currentUser.get('email')}}</div>
        <svg-icon name="arrow-drop-down" class="dropdown-icon"></svg-icon>

        <button class="no-style mobile-toggle"><svg-icon name="menu"></svg-icon></button>
    </div>

    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="logged-in-user-widget-menu">
        <a class="admin-link" mat-menu-item routerLink="/admin" *ngIf="currentUser.hasPermission('admin.access')">
            <svg-icon class="mat-icon" name="dashboard"></svg-icon>
            <span trans>Admin Area</span>
        </a>

        <ng-content></ng-content>

        <a class="account-settings-link" mat-menu-item routerLink="/account/settings">
            <svg-icon class="mat-icon" name="settings"></svg-icon>
            <span trans>Account Settings</span>
        </a>

        <a class="subscription-link" mat-menu-item routerLink="/billing/subscription" *ngIf="currentUser.isSubscribed()">
            <svg-icon class="mat-icon" name="credit-card"></svg-icon>
            <span trans>Subscription</span>
        </a>

        <div class="logout-item" mat-menu-item (click)="auth.logOut()">
            <svg-icon class="mat-icon" name="exit-to-app"></svg-icon>
            <span trans>Log Out</span>
        </div>
    </mat-menu>
</ng-container>