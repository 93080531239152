/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./context-menu-playlist-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../vebto-client/core/translations/translate.directive";
import * as i3 from "../../../../vebto-client/core/translations/translations.service";
import * as i4 from "../../../../vebto-client/core/config/settings.service";
import * as i5 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i6 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i7 from "../../../../vebto-client/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i8 from "../../../../vebto-client/core/ui/breakpoints.service";
import * as i9 from "@angular/cdk/observers";
import * as i10 from "../../../../vebto-client/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i11 from "@angular/common";
import * as i12 from "./context-menu-playlist-panel.component";
import * as i13 from "../../playlists/user-playlists.service";
import * as i14 from "../../playlists/playlists.service";
import * as i15 from "../../../../vebto-client/core/ui/context-menu/context-menu.service";
import * as i16 from "../../../../vebto-client/core/ui/modal.service";
import * as i17 from "../../../../vebto-client/auth/current-user";
import * as i18 from "@angular/router";
import * as i19 from "../../../../vebto-client/core/ui/toast.service";
var styles_ContextMenuPlaylistPanelComponent = [i0.styles];
var RenderType_ContextMenuPlaylistPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ContextMenuPlaylistPanelComponent, data: {} });
export { RenderType_ContextMenuPlaylistPanelComponent as RenderType_ContextMenuPlaylistPanelComponent };
function View_ContextMenuPlaylistPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "playlist no-style"], ["trans", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTracks(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_ContextMenuPlaylistPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "no-style panel-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["name", "arrow-back"]], null, null, null, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i1.ɵdid(2, 114688, null, 0, i6.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "panel-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "title"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(8, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Select"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "button", [["class", "no-style new-playlist"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNewPlaylistModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "svg-icon", [["name", "playlist-add"]], null, null, null, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i1.ɵdid(12, 114688, null, 0, i6.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(14, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["New Playlist"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "playlists"], ["customScrollbar", ""]], null, null, null, null, null)), i1.ɵdid(17, 4341760, null, 0, i7.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i8.BreakpointsService, i1.NgZone, i9.MutationObserverFactory, i4.Settings, i10.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextMenuPlaylistPanelComponent_1)), i1.ɵdid(19, 802816, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "arrow-back"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "playlist-add"; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.userPlaylists.get(); _ck(_v, 19, 0, currVal_2); }, null); }
export function View_ContextMenuPlaylistPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "context-menu-playlist-panel", [], null, null, null, View_ContextMenuPlaylistPanelComponent_0, RenderType_ContextMenuPlaylistPanelComponent)), i1.ɵdid(1, 49152, null, 0, i12.ContextMenuPlaylistPanelComponent, [i13.UserPlaylists, i14.Playlists, i15.ContextMenu, i16.Modal, i17.CurrentUser, i18.Router, i19.Toast], null, null)], null, null); }
var ContextMenuPlaylistPanelComponentNgFactory = i1.ɵccf("context-menu-playlist-panel", i12.ContextMenuPlaylistPanelComponent, View_ContextMenuPlaylistPanelComponent_Host_0, { tracks: "tracks" }, { close$: "close$" }, []);
export { ContextMenuPlaylistPanelComponentNgFactory as ContextMenuPlaylistPanelComponentNgFactory };
