var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PlayerState } from "../player-state.service";
import { NgZone } from "@angular/core";
import { PlayerQueue } from "../player-queue.service";
import { Search } from "../../search/search.service";
import * as Dot from "dot-object";
import { LazyLoaderService } from "vebto-client/core/utils/lazy-loader.service";
var SoundcloudStrategy = /** @class */ (function () {
    /**
     * Html5Strategy Constructor.
     */
    function SoundcloudStrategy(state, zone, queue, search, lazyLoader) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.search = search;
        this.lazyLoader = lazyLoader;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         */
        this.pendingVolume = null;
        /**
         * Loaded track duration in seconds.
         */
        this.duration = 0;
        /**
         * Elapsed time in seconds since the track started playing.
         */
        this.position = 0;
    }
    /**
     * Start playback.
     */
    SoundcloudStrategy.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cueTrack(this.queue.getCurrent())];
                    case 1:
                        _a.sent();
                        this.soundcloud.play();
                        this.state.playing = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause playback.
     */
    SoundcloudStrategy.prototype.pause = function () {
        this.soundcloud.pause();
        this.state.playing = false;
    };
    /**
     * Stop playback.
     */
    SoundcloudStrategy.prototype.stop = function () {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    };
    /**
     * Seek to specified time in track.
     */
    SoundcloudStrategy.prototype.seekTo = function (time) {
        this.soundcloud.seekTo(time * 1000);
    };
    /**
     * Get loaded track duration in seconds.
     */
    SoundcloudStrategy.prototype.getDuration = function () {
        return this.duration / 1000;
    };
    /**
     * Get elapsed time in seconds since the track started playing.
     */
    SoundcloudStrategy.prototype.getCurrentTime = function () {
        return this.position / 1000;
    };
    /**
     * Set soundcloud player volume.
     */
    SoundcloudStrategy.prototype.setVolume = function (number) {
        if (!this.soundcloud) {
            this.pendingVolume = number / 100;
        }
        else {
            this.soundcloud.volume = number / 100;
        }
    };
    /**
     * Mute the player.
     */
    SoundcloudStrategy.prototype.mute = function () {
        this.pendingVolume = this.soundcloud.getVolume();
        this.soundcloud.setVolume(0);
    };
    /**
     * Unmute the player.
     */
    SoundcloudStrategy.prototype.unMute = function () {
        var volume = this.pendingVolume || 50;
        this.soundcloud.setVolume(volume);
    };
    /**
     * Get track that is currently cued for playback.
     */
    SoundcloudStrategy.prototype.getCuedTrack = function () {
        return this.cuedTrack;
    };
    /**
     * Check if youtube player is ready.
     */
    SoundcloudStrategy.prototype.ready = function () {
        return this.bootstrapped;
    };
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    SoundcloudStrategy.prototype.cueTrack = function (track) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var artist, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.cueing === track || this.cuedTrack === track)
                            return [2 /*return*/];
                        this.cueing = track;
                        this.state.buffering = true;
                        if (!!track.youtube_id) return [3 /*break*/, 2];
                        artist = Dot.pick('album.artist.name', track) || track.artists[0];
                        return [4 /*yield*/, this.search.videoId(artist, track.name).toPromise()];
                    case 1:
                        results = _a.sent();
                        track.youtube_id = results[0].id;
                        _a.label = 2;
                    case 2: return [2 /*return*/, new Promise(function (resolve) {
                            _this.bootstrap(track).then(function () {
                                _this.soundcloud.load(track.youtube_id, _this.getPlayerVars(resolve));
                                _this.cuedTrack = track;
                                _this.cueing = null;
                            });
                        })];
                }
            });
        });
    };
    /**
     * Destroy soundcloud playback strategy.
     */
    SoundcloudStrategy.prototype.destroy = function () {
        this.soundcloud && this.soundcloud.remove && this.soundcloud.remove();
        this.soundcloud = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    };
    /**
     * Bootstrap soundcloud player.
     */
    SoundcloudStrategy.prototype.bootstrap = function (track) {
        var _this = this;
        if (this.bootstrapped)
            return new Promise(function (resolve) { return resolve(); });
        return new Promise(function (resolve) {
            _this.lazyLoader.loadScript('https://w.soundcloud.com/player/api.js').then(function () {
                var iframe = document.createElement('iframe');
                iframe.onload = function () {
                    _this.soundcloud = SC.Widget(iframe);
                    _this.handlePlayerStateChangeEvents();
                    resolve();
                };
                iframe.id = 'soundcloud-iframe';
                iframe.src = 'https://w.soundcloud.com/player/?url=' + track.youtube_id + '&color=0066cc';
                document.querySelector('.soundcloud-player').appendChild(iframe);
            });
        });
    };
    /**
     * Handle soundcloud playback state change events.
     */
    SoundcloudStrategy.prototype.handlePlayerStateChangeEvents = function () {
        var _this = this;
        this.soundcloud.bind(SC.Widget.Events.PLAY, function () {
            _this.setState('playing', true);
        });
        this.soundcloud.bind(SC.Widget.Events.PAUSE, function () {
            _this.setState('playing', false);
        });
        this.soundcloud.bind(SC.Widget.Events.PLAY_PROGRESS, function (e) {
            _this.position = e.currentPosition;
        });
        this.soundcloud.bind(SC.Widget.Events.ERROR, function () {
            _this.cuedTrack = null;
            _this.setState('playing', false);
            _this.state.firePlaybackEnded();
        });
        this.soundcloud.bind(SC.Widget.Events.FINISH, function () {
            _this.state.firePlaybackEnded();
            _this.setState('playing', false);
        });
    };
    /**
     * Set specified player state.
     */
    SoundcloudStrategy.prototype.setState = function (name, value) {
        var _this = this;
        this.zone.run(function () { return _this.state[name] = value; });
    };
    /**
     * Handle soundcloud player ready event.
     */
    SoundcloudStrategy.prototype.handlePlayerReadyEvent = function (resolve) {
        var _this = this;
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        this.soundcloud.getDuration(function (duration) {
            _this.duration = duration;
        });
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    };
    /**
     * Get soundcloud player options.
     */
    SoundcloudStrategy.prototype.getPlayerVars = function (resolve) {
        var _this = this;
        return {
            buying: false,
            liking: false,
            download: false,
            sharing: false,
            show_artwork: false,
            show_comments: false,
            show_playcount: false,
            show_user: false,
            callback: function () {
                _this.handlePlayerReadyEvent(resolve);
            }
        };
    };
    return SoundcloudStrategy;
}());
export { SoundcloudStrategy };
