var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { finalize, map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Genre } from "../../../models/Genre";
import { FilterablePage } from "../../filterable-page/filterable-page";
import { Genres } from "../genres.service";
import { Settings } from "vebto-client/core/config/settings.service";
var GenreComponent = /** @class */ (function (_super) {
    __extends(GenreComponent, _super);
    /**
     * GenreComponent Constructor.
     */
    function GenreComponent(route, genres, settings) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.genres = genres;
        _this.settings = settings;
        /**
         * Genre model.
         */
        _this.genre = new Genre();
        /**
         * Whether more artists are being loaded currently.
         */
        _this.loading = false;
        return _this;
    }
    GenreComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.pagination = data.genreData.artistsPagination;
            _this.setItems(_this.pagination.data);
            _this.genre = data.genreData.genre;
        });
    };
    /**
     * Filter genre artists by specified query.
     */
    GenreComponent.prototype.filter = function (query) {
        return this.genres.getGenreArtists(this.genre.name, { query: query })
            .pipe(map(function (response) { return response.artistsPagination.data; }));
    };
    /**
     * Load more artists for current genre.
     */
    GenreComponent.prototype.loadMore = function () {
        var _this = this;
        this.loading = true;
        var params = { page: this.pagination.current_page + 1 };
        this.genres.getGenreArtists(this.genre.name, params).pipe(finalize(function () {
            _this.loading = false;
        })).subscribe(function (response) {
            _this.pagination = response.artistsPagination;
            _this.appendItems(_this.pagination.data);
        });
    };
    /**
     * Check if more artists can be loaded for current genre.
     */
    GenreComponent.prototype.canLoadMore = function () {
        return !this.loading && !this.filterQuery.value && this.pagination.current_page < this.pagination.last_page;
    };
    return GenreComponent;
}(FilterablePage));
export { GenreComponent };
