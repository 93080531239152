var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { finalize } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { ContextMenuComponent } from "../../context-menu/context-menu.component";
import { Player } from "../../player/player.service";
import { UserLibrary } from "../../users/user-library/user-library.service";
import { Lyrics } from "../../lyrics/lyrics.service";
import { LyricsModalComponent } from "../../lyrics/lyrics-modal/lyrics-modal.component";
import { Uploads } from '../../../../vebto-client/core/files/uploads.service';
var TrackContextMenuComponent = /** @class */ (function (_super) {
    __extends(TrackContextMenuComponent, _super);
    /**
     * TrackContextMenuComponent Constructor.
     */
    function TrackContextMenuComponent(player, library, injector, lyrics) {
        var _this = _super.call(this, injector) || this;
        _this.player = player;
        _this.library = library;
        _this.injector = injector;
        _this.lyrics = lyrics;
        return _this;
    }
    /**
     * Check if this track is in player queue.
     */
    TrackContextMenuComponent.prototype.inQueue = function () {
        return this.player.queue.has(this.data.item);
    };
    /**
     * Remove track from player queue.
     */
    TrackContextMenuComponent.prototype.removeFromQueue = function () {
        this.player.queue.remove(this.data.item);
        this.contextMenu.close();
    };
    /**
     * Check if track is in user's library.
     */
    TrackContextMenuComponent.prototype.inLibrary = function () {
        return this.library.has(this.data.item);
    };
    /**
     * Remove track from user's library.
     */
    TrackContextMenuComponent.prototype.removeFromLibrary = function () {
        this.library.remove(this.getTracks());
        this.contextMenu.close();
    };
    /**
     * Copy fully qualified album url to clipboard.
     */
    TrackContextMenuComponent.prototype.copyLinkToClipboard = function () {
        _super.prototype.copyLinkToClipboard.call(this, 'track');
    };
    /**
     * Check if multiple tracks are selected in track list.
     */
    TrackContextMenuComponent.prototype.multipleTracksSelected = function () {
        return this.data.selectedTracks && this.data.selectedTracks.all().length > 1;
    };
    /**
     * Get tracks that should be used by context menu.
     */
    TrackContextMenuComponent.prototype.getTracks = function () {
        return this.getSelectedTracks() || [this.data.item];
    };
    /**
     * Go to track radio route.
     */
    TrackContextMenuComponent.prototype.goToTrackRadio = function () {
        this.contextMenu.close();
        this.router.navigate(this.urls.trackRadio(this.data.item));
    };
    /**
     * Fetch lyrics and show lyrics modal.
     */
    TrackContextMenuComponent.prototype.showLyricsModal = function () {
        var _this = this;
        this.state.loading = true;
        this.contextMenu.close();
        this.lyrics.get(this.data.item.id).pipe(finalize(function () {
            _this.state.loading = false;
        })).subscribe(function (lyric) {
            _this.modal.open(LyricsModalComponent, { lyrics: lyric.text }, 'lyrics-modal-container');
        }, function () {
            _this.toast.open('Could not find lyrics for this song.');
        });
    };
    TrackContextMenuComponent.prototype.downloadTrack = function () {
        var track = this.data.item;
        if (!track)
            return;
        Uploads.downloadFileFromUrl(this.urls.trackDownload(track));
    };
    /**
     * Get image for context menu.
     */
    TrackContextMenuComponent.prototype.getImage = function () {
        if (!this.data.item.album)
            return this.wpImages.getDefault('album');
        return this.data.item.album.image;
    };
    /**
     * Get currently selected tracks, if any.
     */
    TrackContextMenuComponent.prototype.getSelectedTracks = function () {
        if (!this.data.selectedTracks || this.data.selectedTracks.all().length <= 1)
            return;
        return this.data.selectedTracks.all();
    };
    return TrackContextMenuComponent;
}(ContextMenuComponent));
export { TrackContextMenuComponent };
