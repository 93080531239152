/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./connect-social-accounts-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "../../core/translations/translate.directive";
import * as i4 from "../../core/translations/translations.service";
import * as i5 from "../../core/config/settings.service";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "../../core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i10 from "../../core/ui/svg-icon/svg-icon.component";
import * as i11 from "@angular/common";
import * as i12 from "./connect-social-accounts-panel.component";
import * as i13 from "../../auth/social-auth.service";
import * as i14 from "../../core/ui/toast.service";
var styles_ConnectSocialAccountsPanelComponent = [i0.styles];
var RenderType_ConnectSocialAccountsPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConnectSocialAccountsPanelComponent, data: {} });
export { RenderType_ConnectSocialAccountsPanelComponent as RenderType_ConnectSocialAccountsPanelComponent };
function View_ConnectSocialAccountsPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "social-account-username"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "button primary disable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disconnectSocialAccount("google") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(5, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Disable"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); _ck(_v, 3, 0, currVal_1); }); }
function View_ConnectSocialAccountsPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button primary enable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.connectSocialAccount("google") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Enable"]))], function (_ck, _v) { var currVal_1 = "accent"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
function View_ConnectSocialAccountsPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "social-login-row google-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container google"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["name", "google-custom"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i10.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i5.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Google+ Account"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_2)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["googleBlock", 2]], null, 0, null, View_ConnectSocialAccountsPanelComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "google-custom"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getSocialAccountUsername("google"); var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_1, currVal_2); }, null); }
function View_ConnectSocialAccountsPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "social-account-username"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "button primary disable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disconnectSocialAccount("facebook") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(5, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Disable"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); _ck(_v, 3, 0, currVal_1); }); }
function View_ConnectSocialAccountsPanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button primary enable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.connectSocialAccount("facebook") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Enable"]))], function (_ck, _v) { var currVal_1 = "accent"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
function View_ConnectSocialAccountsPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "social-login-row facebook-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container facebook"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["name", "facebook-custom"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i10.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i5.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Facebook Account"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_5)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["facebookBlock", 2]], null, 0, null, View_ConnectSocialAccountsPanelComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "facebook-custom"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getSocialAccountUsername("facebook"); var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_1, currVal_2); }, null); }
function View_ConnectSocialAccountsPanelComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "social-account-username"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "button primary disable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disconnectSocialAccount("twitter") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(5, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Disable"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); _ck(_v, 3, 0, currVal_1); }); }
function View_ConnectSocialAccountsPanelComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button primary enable-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.connectSocialAccount("twitter") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Enable"]))], function (_ck, _v) { var currVal_1 = "accent"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
function View_ConnectSocialAccountsPanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "social-login-row twitter-row last"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container twitter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["name", "twitter-custom"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i10.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i5.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "name"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Twitter Account"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_8)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["twitterBlock", 2]], null, 0, null, View_ConnectSocialAccountsPanelComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "twitter-custom"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getSocialAccountUsername("twitter"); var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_1, currVal_2); }, null); }
export function View_ConnectSocialAccountsPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_4)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConnectSocialAccountsPanelComponent_7)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.get("social.google.enable"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.settings.get("social.facebook.enable"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.settings.get("social.twitter.enable"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ConnectSocialAccountsPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "connect-social-accounts-panel", [], null, null, null, View_ConnectSocialAccountsPanelComponent_0, RenderType_ConnectSocialAccountsPanelComponent)), i1.ɵdid(1, 49152, null, 0, i12.ConnectSocialAccountsPanelComponent, [i13.SocialAuthService, i14.Toast, i5.Settings], null, null)], null, null); }
var ConnectSocialAccountsPanelComponentNgFactory = i1.ɵccf("connect-social-accounts-panel", i12.ConnectSocialAccountsPanelComponent, View_ConnectSocialAccountsPanelComponent_Host_0, { user: "user" }, {}, []);
export { ConnectSocialAccountsPanelComponentNgFactory as ConnectSocialAccountsPanelComponentNgFactory };
