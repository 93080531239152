import { HttpClient, HttpEventType, HttpParams, HttpRequest } from "@angular/common/http";
import { HttpErrorHandler } from "./errors/http-error-handler.service";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./errors/http-error-handler.service";
import * as i3 from "./http.module";
var AppHttpClient = /** @class */ (function () {
    /**
     * AppHttpClient Constructor.
     */
    function AppHttpClient(httpClient, errorHandler) {
        this.httpClient = httpClient;
        this.errorHandler = errorHandler;
    }
    AppHttpClient.prototype.get = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        var httpParams = this.generateHttpParams(params);
        return this.httpClient.get(this.prefixUri(uri), { params: httpParams }).pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.post = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = null; }
        return this.httpClient.post(this.prefixUri(uri), params).pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.put = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        params = this.spoofHttpMethod(params, 'PUT');
        return this.httpClient.post(this.prefixUri(uri), params).pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.delete = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        params = this.spoofHttpMethod(params, 'DELETE');
        return this.httpClient.post(this.prefixUri(uri), params).pipe(catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.postWithProgress = function (uri, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        var req = new HttpRequest('POST', this.prefixUri(uri), params, {
            reportProgress: true
        });
        return this.httpClient.request(req).pipe(map(function (event) { return _this.getEventMessage(event); }), catchError(function (err) { return _this.errorHandler.handle(err, uri); }));
    };
    AppHttpClient.prototype.getEventMessage = function (event) {
        switch (event.type) {
            case HttpEventType.Sent:
                return { type: event.type, name: 'upload_started' };
            case HttpEventType.UploadProgress:
                return {
                    type: event.type,
                    name: 'upload_progress',
                    progress: Math.round(100 * event.loaded / event.total)
                };
            case HttpEventType.Response:
                return { type: event.type, name: 'upload_completed', body: event.body };
            default:
                return { type: event.type, name: 'other' };
        }
    };
    /**
     * Prefix specified uri with backend API prefix.
     */
    AppHttpClient.prototype.prefixUri = function (uri) {
        if (uri.indexOf('://') > -1)
            return uri;
        return AppHttpClient.prefix + '/' + uri;
    };
    /**
     * Generate http params for GET request.
     */
    AppHttpClient.prototype.generateHttpParams = function (params) {
        var httpParams = new HttpParams();
        for (var key in params) {
            httpParams = httpParams.append(key, params[key]);
        }
        return httpParams;
    };
    /**
     * Spoof http method by adding "_method" to request params.
     */
    AppHttpClient.prototype.spoofHttpMethod = function (params, method) {
        if (params instanceof FormData) {
            params.append('_method', method);
        }
        else {
            params['_method'] = method;
        }
        return params;
    };
    AppHttpClient.prefix = 'secure';
    AppHttpClient.ngInjectableDef = i0.defineInjectable({ factory: function AppHttpClient_Factory() { return new AppHttpClient(i0.inject(i1.HttpClient), i0.inject(i2.HttpErrorHandler)); }, token: AppHttpClient, providedIn: i3.HttpModule });
    return AppHttpClient;
}());
export { AppHttpClient };
