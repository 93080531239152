/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./repeat-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i11 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i12 from "../../../../../vebto-client/core/config/settings.service";
import * as i13 from "@angular/common";
import * as i14 from "./repeat-button.component";
import * as i15 from "../../player.service";
var styles_RepeatButtonComponent = [i0.styles];
var RenderType_RepeatButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RepeatButtonComponent, data: {} });
export { RenderType_RepeatButtonComponent as RenderType_RepeatButtonComponent };
function View_RepeatButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["class", "player-action repeat-button"], ["mat-icon-button", ""], ["matTooltip", "Repeat"]], [[2, "active", null], [8, "disabled", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.toggleRepeatMode() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), i1.ɵdid(2, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "svg-icon", [["name", "repeat"]], null, null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i1.ɵdid(4, 114688, null, 0, i11.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i12.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = "Repeat"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "repeat"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player.getState().repeating; var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_RepeatButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["class", "player-action repeat-button active"], ["mat-icon-button", ""], ["matTooltip", "Repeat One"]], [[8, "disabled", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.player.toggleRepeatMode() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), i1.ɵdid(2, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "svg-icon", [["name", "repeat-one"]], null, null, null, i10.View_SvgIconComponent_0, i10.RenderType_SvgIconComponent)), i1.ɵdid(4, 114688, null, 0, i11.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i12.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = "Repeat One"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "repeat-one"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
export function View_RepeatButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.player.getState().repeatingOne; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.player.getState().repeatingOne; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RepeatButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "repeat-button", [], null, null, null, View_RepeatButtonComponent_0, RenderType_RepeatButtonComponent)), i1.ɵdid(1, 49152, null, 0, i14.RepeatButtonComponent, [i15.Player], null, null)], null, null); }
var RepeatButtonComponentNgFactory = i1.ɵccf("repeat-button", i14.RepeatButtonComponent, View_RepeatButtonComponent_Host_0, {}, {}, []);
export { RepeatButtonComponentNgFactory as RepeatButtonComponentNgFactory };
