/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./library-track-toggle-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i7 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i8 from "../../../../../vebto-client/core/config/settings.service";
import * as i9 from "@angular/common";
import * as i10 from "./library-track-toggle-button.component";
import * as i11 from "../user-library.service";
var styles_LibraryTrackToggleButtonComponent = [i0.styles];
var RenderType_LibraryTrackToggleButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LibraryTrackToggleButtonComponent, data: {} });
export { RenderType_LibraryTrackToggleButtonComponent as RenderType_LibraryTrackToggleButtonComponent };
function View_LibraryTrackToggleButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.library.add(_co.track) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "svg-icon", [["name", "add"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = "add"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
function View_LibraryTrackToggleButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "remove-button"], ["mat-icon-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.library.remove(_co.track) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "svg-icon", [["class", "check-icon"], ["name", "check"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "svg-icon", [["class", "remove-icon"], ["name", "clear"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(5, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = "check"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "clear"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); }); }
export function View_LibraryTrackToggleButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryTrackToggleButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryTrackToggleButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.library.has(_co.track); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.library.has(_co.track); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LibraryTrackToggleButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "library-track-toggle-button", [], null, null, null, View_LibraryTrackToggleButtonComponent_0, RenderType_LibraryTrackToggleButtonComponent)), i1.ɵdid(1, 49152, null, 0, i10.LibraryTrackToggleButtonComponent, [i11.UserLibrary], null, null)], null, null); }
var LibraryTrackToggleButtonComponentNgFactory = i1.ɵccf("library-track-toggle-button", i10.LibraryTrackToggleButtonComponent, View_LibraryTrackToggleButtonComponent_Host_0, { track: "track" }, {}, []);
export { LibraryTrackToggleButtonComponentNgFactory as LibraryTrackToggleButtonComponentNgFactory };
