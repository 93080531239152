/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sorting-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/menu";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../vebto-client/core/translations/translate.directive";
import * as i7 from "../../../../vebto-client/core/translations/translations.service";
import * as i8 from "../../../../vebto-client/core/config/settings.service";
import * as i9 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i10 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i11 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i12 from "./sorting-header.component";
var styles_SortingHeaderComponent = [i0.styles];
var RenderType_SortingHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SortingHeaderComponent, data: {} });
export { RenderType_SortingHeaderComponent as RenderType_SortingHeaderComponent };
export function View_SortingHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "button", [["aria-haspopup", "true"], ["class", "no-style header-content"]], null, [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 1196032, null, 0, i2.MatMenuTrigger, [i3.Overlay, i1.ElementRef, i1.ViewContainerRef, i2.MAT_MENU_SCROLL_STRATEGY, [2, i2.MatMenu], [8, null], [2, i4.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i6.TranslateDirective, [i1.ElementRef, i7.Translations, i8.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Sorted by"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "current-order"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "svg-icon", [["name", "sort"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(8, 114688, null, 0, i10.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "mat-menu", [["xPosition", "before"]], null, null, null, i11.View_MatMenu_0, i11.RenderType_MatMenu)), i1.ɵprd(6144, null, i2.ɵe24, null, [i2.MatMenu]), i1.ɵdid(11, 1294336, [["matMenu", 4]], 2, i2.MatMenu, [i1.ElementRef, i1.NgZone, i2.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], overlapTrigger: [1, "overlapTrigger"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), i1.ɵncd(0, 0)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11); _ck(_v, 1, 0, currVal_0); var currVal_2 = "sort"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "before"; var currVal_4 = false; _ck(_v, 11, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.name; _ck(_v, 6, 0, currVal_1); }); }
export function View_SortingHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "sorting-header", [], null, null, null, View_SortingHeaderComponent_0, RenderType_SortingHeaderComponent)), i1.ɵdid(1, 49152, null, 1, i12.SortingHeaderComponent, [i7.Translations], null, null), i1.ɵqud(603979776, 1, { menuItems: 1 })], null, null); }
var SortingHeaderComponentNgFactory = i1.ɵccf("sorting-header", i12.SortingHeaderComponent, View_SortingHeaderComponent_Host_0, { name: "name" }, {}, ["*"]);
export { SortingHeaderComponentNgFactory as SortingHeaderComponentNgFactory };
