/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./library-playlists.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../vebto-client/core/translations/translate.directive";
import * as i5 from "../../../../../vebto-client/core/translations/translations.service";
import * as i6 from "../../../../../vebto-client/core/config/settings.service";
import * as i7 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i8 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i9 from "./library-playlists.component";
import * as i10 from "../../../web-player-urls.service";
import * as i11 from "../../../../../vebto-client/core/ui/context-menu/context-menu.service";
import * as i12 from "../../../web-player-images.service";
var styles_LibraryPlaylistsComponent = [i0.styles];
var RenderType_LibraryPlaylistsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LibraryPlaylistsComponent, data: {} });
export { RenderType_LibraryPlaylistsComponent as RenderType_LibraryPlaylistsComponent };
function View_LibraryPlaylistsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "playlist"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "tracks-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(10, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Songs"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "no-style options-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showContextMenu(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "svg-icon", [["name", "more-vert"]], null, null, null, i7.View_SvgIconComponent_0, i7.RenderType_SvgIconComponent)), i1.ɵdid(14, 114688, null, 0, i8.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i6.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.playlist(_v.context.$implicit); _ck(_v, 2, 0, currVal_2); var currVal_6 = "more-vert"; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.getImage(_v.context.$implicit); _ck(_v, 3, 0, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.context.$implicit["tracks_count"]; _ck(_v, 8, 0, currVal_5); }); }
export function View_LibraryPlaylistsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "playlists"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LibraryPlaylistsComponent_1)), i1.ɵdid(2, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.playlists; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LibraryPlaylistsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "library-playlists", [["class", "user-library-page"]], null, null, null, View_LibraryPlaylistsComponent_0, RenderType_LibraryPlaylistsComponent)), i1.ɵdid(1, 114688, null, 0, i9.LibraryPlaylistsComponent, [i10.WebPlayerUrls, i6.Settings, i11.ContextMenu, i2.ActivatedRoute, i12.WebPlayerImagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LibraryPlaylistsComponentNgFactory = i1.ɵccf("library-playlists", i9.LibraryPlaylistsComponent, View_LibraryPlaylistsComponent_Host_0, {}, {}, []);
export { LibraryPlaylistsComponentNgFactory as LibraryPlaylistsComponentNgFactory };
