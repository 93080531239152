import {NgModule} from '@angular/core';
import {TranslateDirective} from './translate.directive';

@NgModule({
    declarations: [
        TranslateDirective,
    ],
    exports: [
        TranslateDirective,
    ],
})
export class TranslationsModule {}
