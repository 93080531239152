import { MatSnackBar } from "@angular/material";
import { Settings } from "../config/settings.service";
import { Translations } from "../translations/translations.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
import * as i2 from "../translations/translations.service";
import * as i3 from "@angular/material/snack-bar";
var Toast = /** @class */ (function () {
    /**
     * Toast Constructor.
     */
    function Toast(settings, i18n, snackbar) {
        this.settings = settings;
        this.i18n = i18n;
        this.snackbar = snackbar;
    }
    Toast.prototype.open = function (message, config) {
        if (config === void 0) { config = {}; }
        if (!config.duration && config.duration !== 0) {
            config.duration = this.settings.get('toast.default_timeout', 3000);
        }
        return this.snackbar.open(this.i18n.t(message), config.action, { duration: config.duration });
    };
    Toast.ngInjectableDef = i0.defineInjectable({ factory: function Toast_Factory() { return new Toast(i0.inject(i1.Settings), i0.inject(i2.Translations), i0.inject(i3.MatSnackBar)); }, token: Toast, providedIn: "root" });
    return Toast;
}());
export { Toast };
