var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PlayerState } from "../player-state.service";
import { NgZone } from "@angular/core";
import { PlayerQueue } from "../player-queue.service";
import { WebPlayerImagesService } from '../../web-player-images.service';
var Html5Strategy = /** @class */ (function () {
    /**
     * Html5Strategy Constructor.
     */
    function Html5Strategy(state, zone, queue, wpImages) {
        this.state = state;
        this.zone = zone;
        this.queue = queue;
        this.wpImages = wpImages;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after player is bootstrapped.
         * Number between 1 and 100.
         */
        this.pendingVolume = null;
    }
    /**
     * Start playback.
     */
    Html5Strategy.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cueTrack(this.queue.getCurrent())];
                    case 1:
                        _a.sent();
                        this.html5.play();
                        this.state.playing = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Pause playback.
     */
    Html5Strategy.prototype.pause = function () {
        this.html5.pause();
        this.state.playing = false;
    };
    /**
     * Stop playback.
     */
    Html5Strategy.prototype.stop = function () {
        this.pause();
        this.seekTo(0);
        this.state.playing = false;
    };
    /**
     * Seek to specified time in track.
     */
    Html5Strategy.prototype.seekTo = function (time) {
        this.html5.currentTime = time;
    };
    /**
     * Get loaded track duration in seconds.
     */
    Html5Strategy.prototype.getDuration = function () {
        if (!this.html5.seekable.length)
            return 0;
        return this.html5.seekable.end(0);
    };
    /**
     * Get elapsed time in seconds since the track started playing
     */
    Html5Strategy.prototype.getCurrentTime = function () {
        return this.html5.currentTime;
    };
    /**
     * Set html5 player volume to float between 0 and 1.
     */
    Html5Strategy.prototype.setVolume = function (number) {
        if (!this.html5) {
            this.pendingVolume = number;
        }
        else {
            this.html5.volume = number / 100;
        }
    };
    /**
     * Mute the player.
     */
    Html5Strategy.prototype.mute = function () {
        this.html5.muted = true;
    };
    /**
     * Unmute the player.
     */
    Html5Strategy.prototype.unMute = function () {
        this.html5.muted = false;
    };
    /**
     * Get track that is currently cued for playback.
     */
    Html5Strategy.prototype.getCuedTrack = function () {
        return this.cuedTrack;
    };
    /**
     * Check if youtube player is ready.
     */
    Html5Strategy.prototype.ready = function () {
        return this.bootstrapped;
    };
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    Html5Strategy.prototype.cueTrack = function (track) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.cueing === track || this.cuedTrack === track)
                    return [2 /*return*/];
                this.cueing = track;
                this.state.buffering = true;
                this.bootstrap();
                this.html5.src = track.url;
                this.html5.poster = this.wpImages.getTrackImage(track);
                this.cuedTrack = track;
                this.cueing = null;
                return [2 /*return*/, new Promise(function (resolve) { return resolve(); })];
            });
        });
    };
    /**
     * Destroy html5 playback strategy.
     */
    Html5Strategy.prototype.destroy = function () {
        this.html5 && this.html5.remove();
        this.html5 = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
    };
    /**
     * Bootstrap html5 player.
     */
    Html5Strategy.prototype.bootstrap = function () {
        if (this.bootstrapped)
            return;
        this.html5 = document.createElement('video');
        this.html5.setAttribute('playsinline', 'true');
        this.html5.id = 'html5-player';
        document.querySelector('.html5-player').appendChild(this.html5);
        this.handlePlayerReadyEvent();
        this.handlePlayerStateChangeEvents();
        this.bootstrapped = true;
    };
    /**
     * Handle html5 playback state change events.
     */
    Html5Strategy.prototype.handlePlayerStateChangeEvents = function () {
        var _this = this;
        this.html5.addEventListener('ended', function () {
            _this.state.firePlaybackEnded();
            _this.setState('playing', false);
        });
        this.html5.addEventListener('playing', function (e) {
            _this.setState('playing', true);
        });
        this.html5.addEventListener('pause', function () {
            _this.setState('playing', false);
        });
        this.html5.addEventListener('error', function () {
            _this.cuedTrack = null;
            _this.setState('playing', false);
            _this.state.firePlaybackEnded();
        });
    };
    /**
     * Set specified player state.
     */
    Html5Strategy.prototype.setState = function (name, value) {
        var _this = this;
        this.zone.run(function () { return _this.state[name] = value; });
    };
    /**
     * Handle html5 player ready event.
     */
    Html5Strategy.prototype.handlePlayerReadyEvent = function (resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        resolve && resolve();
        this.state.fireReadyEvent();
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    };
    return Html5Strategy;
}());
export { Html5Strategy };
