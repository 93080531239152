import { Routes } from '@angular/router';
import { AuthGuard } from "../guards/auth-guard.service";
import { AccountSettingsComponent } from "./account-settings.component";
import { AccountSettingsResolve } from "./account-settings-resolve.service";
var ɵ0 = { name: 'account-settings' };
var routes = [
    // {
    //     path: 'account',
    //     pathMatch: 'prefix',
    //     redirectTo: 'account/settings'
    // },
    {
        path: 'account/settings',
        component: AccountSettingsComponent,
        resolve: { resolves: AccountSettingsResolve },
        canActivate: [AuthGuard],
        data: ɵ0,
    },
];
var AccountSettingsRoutingModule = /** @class */ (function () {
    function AccountSettingsRoutingModule() {
    }
    return AccountSettingsRoutingModule;
}());
export { AccountSettingsRoutingModule };
export { ɵ0 };
