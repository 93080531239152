/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-indicator.component";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoadingIndicatorComponent, data: { "animation": [{ type: 7, name: "visibility", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { backgroundColor: "*", display: "block" }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { backgroundColor: "transparent", display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "true <=> false", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(.4,0,.2,1)" }, options: null }], options: {} }] } });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
function View_LoadingIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "rect rect1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "rect rect2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "rect rect3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "rect rect4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "rect rect5"]], null, null, null, null, null))], null, null); }
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [], [[40, "@visibility", 0]], null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingIndicatorComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isVisible; _ck(_v, 0, 0, currVal_0); }); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("loading-indicator", i3.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, { isVisible: "isVisible" }, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };
