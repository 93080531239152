import { HttpCacheClient } from "../../core/http/http-cache-client";
import * as i0 from "@angular/core";
import * as i1 from "../http/http-cache-client";
var Pages = /** @class */ (function () {
    /**
     * TagsService Constructor.
     */
    function Pages(http) {
        this.http = http;
        /**
         * Base uri for pages api.
         */
        this.baseUri = 'pages';
    }
    /**
     * Fetch all existing pages from the server.
     */
    Pages.prototype.getAll = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.getWithCache(this.baseUri, params);
    };
    /**
     * Fetch a page matching specified id.
     */
    Pages.prototype.get = function (id) {
        return this.http.getWithCache(this.baseUri + '/' + id);
    };
    /**
     * Create a new page.
     */
    Pages.prototype.create = function (data) {
        return this.http.post(this.baseUri, data);
    };
    /**
     * Update existing page.
     */
    Pages.prototype.update = function (id, data) {
        return this.http.put(this.baseUri + '/' + id, data);
    };
    /**
     * Delete specified pages.
     */
    Pages.prototype.delete = function (ids) {
        return this.http.delete(this.baseUri, { ids: ids });
    };
    Pages.ngInjectableDef = i0.defineInjectable({ factory: function Pages_Factory() { return new Pages(i0.inject(i1.HttpCacheClient)); }, token: Pages, providedIn: "root" });
    return Pages;
}());
export { Pages };
