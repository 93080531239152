import { Router } from '@angular/router';
import { CurrentUser } from "vebto-client/auth/current-user";
import { AuthService } from "../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "../auth/auth.service";
import * as i3 from "@angular/router";
import * as i4 from "../auth/auth.module";
var GuestGuard = /** @class */ (function () {
    function GuestGuard(currentUser, auth, router) {
        this.currentUser = currentUser;
        this.auth = auth;
        this.router = router;
    }
    GuestGuard.prototype.canActivate = function (route, state) {
        if (!this.currentUser.isLoggedIn())
            return true;
        this.router.navigate([this.auth.getRedirectUri()]);
        return false;
    };
    GuestGuard.ngInjectableDef = i0.defineInjectable({ factory: function GuestGuard_Factory() { return new GuestGuard(i0.inject(i1.CurrentUser), i0.inject(i2.AuthService), i0.inject(i3.Router)); }, token: GuestGuard, providedIn: i4.AuthModule });
    return GuestGuard;
}());
export { GuestGuard };
