/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-playback-buttons.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i7 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i8 from "../../../../../vebto-client/core/config/settings.service";
import * as i9 from "./main-playback-buttons.component";
import * as i10 from "../../player.service";
var styles_MainPlaybackButtonsComponent = [i0.styles];
var RenderType_MainPlaybackButtonsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MainPlaybackButtonsComponent, data: {} });
export { RenderType_MainPlaybackButtonsComponent as RenderType_MainPlaybackButtonsComponent };
export function View_MainPlaybackButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "previous-track"], ["mat-icon-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.playPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "svg-icon", [["name", "skip-previous"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(3, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "play-track"], ["mat-icon-button", ""]], [[2, "hidden", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "svg-icon", [["name", "play-arrow"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(7, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "pause-track"], ["mat-icon-button", ""]], [[2, "hidden", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.pause() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "svg-icon", [["name", "pause"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(11, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "buffering"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "next-track"], ["mat-icon-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.player.playNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "svg-icon", [["name", "skip-next"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(17, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.player.ready(); _ck(_v, 1, 0, currVal_1); var currVal_2 = "skip-previous"; _ck(_v, 3, 0, currVal_2); var currVal_5 = !_co.player.ready(); _ck(_v, 5, 0, currVal_5); var currVal_6 = "play-arrow"; _ck(_v, 7, 0, currVal_6); var currVal_9 = !_co.player.ready(); _ck(_v, 9, 0, currVal_9); var currVal_10 = "pause"; _ck(_v, 11, 0, currVal_10); var currVal_13 = !_co.player.ready(); _ck(_v, 15, 0, currVal_13); var currVal_14 = "skip-next"; _ck(_v, 17, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.player.isPlaying() || _co.player.isBuffering()); var currVal_4 = (i1.ɵnov(_v, 5).disabled || null); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_7 = !_co.player.isPlaying(); var currVal_8 = (i1.ɵnov(_v, 9).disabled || null); _ck(_v, 8, 0, currVal_7, currVal_8); var currVal_11 = !_co.player.isBuffering(); _ck(_v, 12, 0, currVal_11); var currVal_12 = (i1.ɵnov(_v, 15).disabled || null); _ck(_v, 14, 0, currVal_12); }); }
export function View_MainPlaybackButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-playback-buttons", [], null, null, null, View_MainPlaybackButtonsComponent_0, RenderType_MainPlaybackButtonsComponent)), i1.ɵdid(1, 49152, null, 0, i9.MainPlaybackButtonsComponent, [i10.Player], null, null)], null, null); }
var MainPlaybackButtonsComponentNgFactory = i1.ɵccf("main-playback-buttons", i9.MainPlaybackButtonsComponent, View_MainPlaybackButtonsComponent_Host_0, {}, {}, []);
export { MainPlaybackButtonsComponentNgFactory as MainPlaybackButtonsComponentNgFactory };
