import { ComponentFactoryResolver, ComponentRef, Injector, NgZone, Type, ElementRef } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { ComponentPortal } from "@angular/cdk/portal";
import { Overlay } from "@angular/cdk/overlay";
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "../ui.module";
var ORIGIN_ID = 'dynamic-context-menu-origin';
var ContextMenu = /** @class */ (function () {
    /**
     * ModalService constructor.
     */
    function ContextMenu(router, zone, injector, resolver, overlay) {
        var _this = this;
        this.router = router;
        this.zone = zone;
        this.injector = injector;
        this.resolver = resolver;
        this.overlay = overlay;
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationStart; }))
            .subscribe(function () { return _this.close(); });
    }
    /**
     * Show specified modal.
     */
    ContextMenu.prototype.open = function (component, e, params) {
        this.close();
        var componentRef = this.createOverlay(e, params).attach(new ComponentPortal(component));
        componentRef.instance['data'] = params.data || {};
        return componentRef;
    };
    /**
     * Close currently open context menu.
     */
    ContextMenu.prototype.close = function () {
        this.removeLastOrigin();
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    };
    ContextMenu.prototype.createOverlay = function (e, params) {
        var _this = this;
        this.overlayRef = this.overlay.create({
            positionStrategy: this.getMenuPositionStrategy(e, params),
            scrollStrategy: this.overlay.scrollStrategies.close(),
            hasBackdrop: true,
            backdropClass: 'context-menu-backdrop',
            panelClass: 'context-menu-overlay'
        });
        this.overlayRef.backdropClick().subscribe(function () {
            _this.close();
        });
        this.overlayRef.attachments().subscribe(function () {
            _this.overlayRef.backdropElement.addEventListener('contextmenu', function (e) {
                e.preventDefault();
                _this.close();
            });
        });
        this.overlayRef.detachments().subscribe(function () {
            _this.close();
        });
        return this.overlayRef;
    };
    ContextMenu.prototype.getMenuPositionStrategy = function (e, params) {
        this.createOriginFromEvent(e, params);
        var primary = {
            originX: params.originX || 'end',
            originY: params.originY || 'bottom',
            overlayX: params.overlayX || 'start',
            overlayY: params.overlayY || 'top',
        };
        return this.overlay.position().flexibleConnectedTo(new ElementRef(this.lastOrigin))
            .withPositions([
            primary,
            { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' }
        ]);
    };
    ContextMenu.prototype.createOriginFromEvent = function (e, params) {
        if (e instanceof HTMLElement || e instanceof EventTarget) {
            this.lastOrigin = e;
        }
        else {
            this.lastOrigin = document.createElement('div');
            this.lastOrigin.style.position = 'fixed';
            this.lastOrigin.style.top = e.clientY + (params.offsetY || 0) + 'px';
            this.lastOrigin.style.left = e.clientX + (params.offsetX || 0) + 'px';
            this.lastOrigin.id = ORIGIN_ID;
            document.body.appendChild(this.lastOrigin);
        }
    };
    ContextMenu.prototype.removeLastOrigin = function () {
        if (this.lastOrigin && this.lastOrigin.id === ORIGIN_ID) {
            this.lastOrigin.remove();
        }
    };
    ContextMenu.ngInjectableDef = i0.defineInjectable({ factory: function ContextMenu_Factory() { return new ContextMenu(i0.inject(i1.Router), i0.inject(i0.NgZone), i0.inject(i0.INJECTOR), i0.inject(i0.ComponentFactoryResolver), i0.inject(i2.Overlay)); }, token: ContextMenu, providedIn: i3.UiModule });
    return ContextMenu;
}());
export { ContextMenu };
