/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playing-indicator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i4 from "../../../../../vebto-client/core/config/settings.service";
import * as i5 from "./playing-indicator.component";
var styles_PlayingIndicatorComponent = [i0.styles];
var RenderType_PlayingIndicatorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlayingIndicatorComponent, data: {} });
export { RenderType_PlayingIndicatorComponent as RenderType_PlayingIndicatorComponent };
export function View_PlayingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["name", "volume-up"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "volume-up"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlayingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "playing-indicator", [], null, null, null, View_PlayingIndicatorComponent_0, RenderType_PlayingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i5.PlayingIndicatorComponent, [], null, null)], null, null); }
var PlayingIndicatorComponentNgFactory = i1.ɵccf("playing-indicator", i5.PlayingIndicatorComponent, View_PlayingIndicatorComponent_Host_0, {}, {}, []);
export { PlayingIndicatorComponentNgFactory as PlayingIndicatorComponentNgFactory };
