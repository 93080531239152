var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CurrentUser } from "vebto-client/auth/current-user";
import { Router } from "@angular/router";
import { Toast } from "../../ui/toast.service";
import { Translations } from "../../translations/translations.service";
import { HttpErrorHandler } from './http-error-handler.service';
var BackendHttpErrorHandler = /** @class */ (function (_super) {
    __extends(BackendHttpErrorHandler, _super);
    /**
     * HttpErrorHandler Constructor.
     */
    function BackendHttpErrorHandler(i18n, currentUser, router, toast) {
        var _this = _super.call(this, i18n) || this;
        _this.i18n = i18n;
        _this.currentUser = currentUser;
        _this.router = router;
        _this.toast = toast;
        return _this;
    }
    /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    BackendHttpErrorHandler.prototype.handle403Error = function (response) {
        //if user doesn't have access, navigate to login page
        if (this.currentUser.isLoggedIn()) {
            var msg = "You don't have required permissions to do that.";
            this.toast.open(response['message'] ? response['message'] : msg);
        }
        else {
            this.router.navigate(['/login']);
        }
    };
    return BackendHttpErrorHandler;
}(HttpErrorHandler));
export { BackendHttpErrorHandler };
