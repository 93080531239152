/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./svg-icon.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./svg-icon.component";
import * as i3 from "../../config/settings.service";
var styles_SvgIconComponent = [i0.styles];
var RenderType_SvgIconComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SvgIconComponent, data: {} });
export { RenderType_SvgIconComponent as RenderType_SvgIconComponent };
export function View_SvgIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.path; _ck(_v, 1, 0, currVal_0); }); }
export function View_SvgIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [], null, null, null, View_SvgIconComponent_0, RenderType_SvgIconComponent)), i1.ɵdid(1, 114688, null, 0, i2.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i3.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SvgIconComponentNgFactory = i1.ɵccf("svg-icon", i2.SvgIconComponent, View_SvgIconComponent_Host_0, { name: "name" }, {}, []);
export { SvgIconComponentNgFactory as SvgIconComponentNgFactory };
