<div class="modal-header">
    <h2 class="mat-dialog-title" mat-dialog-title trans>Upload File</h2>
    <button type="button" (click)="close()" class="close-button no-style icon-button"><svg-icon name="close"></svg-icon></button>
</div>

<div class="mat-dialog-content" mat-dialog-content>
    <div class="upload-panel">
        <div class="menu">
            <button class="menu-item upload-tab-button no-style" [class.active]="activeTab === 'upload'" (click)="setActiveTab('upload')" trans>Upload</button>
            <button class="menu-item link-tab-button no-style" [class.active]="activeTab === 'link'" (click)="setActiveTab('link')" trans>Link</button>
        </div>

        <mat-progress-bar mode="determinate" [value]="uploadProgress" color="accent" *ngIf="uploadProgress !== null"></mat-progress-bar>
        
        <div class="tabs">
            <div class="tab upload" *ngIf="activeTab === 'upload'">
                <div class="dropzone" fileDropzone (onUpload)="uploadFiles($event)" trans>Drop Files Here or Click to Upload</div>
            </div>
            <div class="tab link" *ngIf="activeTab === 'link'">
                <div class="input-container">
                    <label for="image-link">File URL</label>
                    <input type="text" id="image-link" (change)="setLinkModel($event.target['value'])">
                    <p trans>It must be direct link to a file. <br> Example: http://site.com/image.png</p>
                </div>
            </div>
        </div>

        <div class="errors" *ngIf="errors">
            <div class="error" *ngFor="let error of errors | mapToIterable">{{error.value}}</div>
        </div>
    </div>
</div>

<div class="mat-dialog-actions" mat-dialog-actions>
    <button (click)="close()" type="button" mat-button class="button cancel" trans>Cancel</button>
    <button type="submit" class="button primary submit-button" mat-raised-button color="accent" (click)="confirm()" trans>Upload</button>
</div>