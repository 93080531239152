var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { ContextMenuComponent } from "../../context-menu/context-menu.component";
import { Playlists } from "../playlists.service";
import { UserPlaylists } from "../user-playlists.service";
import { CrupdatePlaylistModalComponent } from "../crupdate-playlist-modal/crupdate-playlist-modal.component";
import { ConfirmModalComponent } from "vebto-client/core/ui/confirm-modal/confirm-modal.component";
var PlaylistContextMenuComponent = /** @class */ (function (_super) {
    __extends(PlaylistContextMenuComponent, _super);
    /**
     * PlaylistContextMenuComponent Constructor.
     */
    function PlaylistContextMenuComponent(injector, playlists, userPlaylists) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.playlists = playlists;
        _this.userPlaylists = userPlaylists;
        return _this;
    }
    PlaylistContextMenuComponent.prototype.getImage = function () {
        return this.data.item.image || (this.data.extra && this.data.extra.image);
    };
    /**
     * Copy fully qualified playlist url to clipboard.
     */
    PlaylistContextMenuComponent.prototype.copyLinkToClipboard = function () {
        _super.prototype.copyLinkToClipboard.call(this, 'playlist');
    };
    /**
     * Get tracks that should be used by context menu.
     */
    PlaylistContextMenuComponent.prototype.getTracks = function () {
        return [];
    };
    /**
     * Delete the playlist after user confirms.
     */
    PlaylistContextMenuComponent.prototype.maybeDeletePlaylist = function () {
        var _this = this;
        this.contextMenu.close();
        this.modal.open(ConfirmModalComponent, {
            title: 'Delete Playlist',
            body: 'Are you sure you want to delete this playlist?',
            ok: 'Delete'
        }).afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            _this.contextMenu.close();
            _this.playlists.delete([_this.data.item.id]).subscribe();
            _this.maybeNavigateFromPlaylistRoute();
        });
    };
    /**
     * Open playlist edit modal.
     */
    PlaylistContextMenuComponent.prototype.openEditModal = function () {
        this.contextMenu.close();
        this.modal.open(CrupdatePlaylistModalComponent, { playlist: this.data.item }, 'crupdate-playlist-modal-container');
    };
    /**
     * Check if current user is creator of playlist.
     */
    PlaylistContextMenuComponent.prototype.userIsCreator = function () {
        return this.userPlaylists.isCreator(this.data.item);
    };
    /**
     * Check if current user is following this playlist.
     */
    PlaylistContextMenuComponent.prototype.userIsFollowing = function () {
        return this.userPlaylists.following(this.data.item.id);
    };
    /**
     * Follow this playlist with current user.
     */
    PlaylistContextMenuComponent.prototype.follow = function () {
        this.userPlaylists.follow(this.data.item);
        this.contextMenu.close();
    };
    /**
     * Unfollow this playlist with current user.
     */
    PlaylistContextMenuComponent.prototype.unfollow = function () {
        this.userPlaylists.unfollow(this.data.item);
        this.contextMenu.close();
    };
    /**
     * Check if playlist is private.
     */
    PlaylistContextMenuComponent.prototype.isPublic = function () {
        return this.data.item.public;
    };
    /**
     * Make playlist public.
     */
    PlaylistContextMenuComponent.prototype.makePublic = function () {
        var _this = this;
        this.contextMenu.close();
        return this.playlists.update(this.data.item.id, { 'public': 1 }).subscribe(function () {
            _this.data.item.public = 1;
        });
    };
    /**
     * Make playlist private.
     */
    PlaylistContextMenuComponent.prototype.makePrivate = function () {
        var _this = this;
        this.contextMenu.close();
        return this.playlists.update(this.data.item.id, { 'public': 0 }).subscribe(function () {
            _this.data.item.public = 0;
        });
    };
    /**
     * Navigate from playlist route if current playlist's route is open.
     */
    PlaylistContextMenuComponent.prototype.maybeNavigateFromPlaylistRoute = function () {
        if (this.router.url.indexOf('playlists/' + this.data.item.id) > -1) {
            this.router.navigate(['/']);
        }
    };
    return PlaylistContextMenuComponent;
}(ContextMenuComponent));
export { PlaylistContextMenuComponent };
