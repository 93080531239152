<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Credentials Required</h2>
    <button type="button" (click)="close()" class="close-button no-style icon-button"><svg-icon name="close"></svg-icon></button>
</div>

<form (ngSubmit)="confirm()" ngNativeValidate>
    <div class="mat-dialog-content" mat-dialog-content customScrollbar>
        <div class="many-inputs">
            <div class="input-container email-address-container" *ngIf="shouldCredentialBeRequested('email')">
                <label for="email" trans>Email Address</label>
                <input type="email" name="email" id="email" [(ngModel)]="model.email" required>
                <div class="error" *ngIf="errors.email">{{errors.email}}</div>
                <p trans>We need your email address to create an account for you, please enter it above.</p>
            </div>

            <div class="input-container password-container" *ngIf="shouldCredentialBeRequested('password')">
                <label for="password" trans>Password</label>
                <input type="password" name="password" id="password" [(ngModel)]="model.password" required>
                <div class="error" *ngIf="errors.password">{{errors.password}}</div>
                <p trans>An account with this email address already exists, if you want to connect the two account's please enter existing accounts password above.</p>
            </div>
        </div>
    </div>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button (click)="close()" type="button" class="button cancel" mat-button trans>Cancel</button>
        <button type="submit" class="button submit-button" mat-raised-button color="accent" trans>Connect</button>
    </div>
</form>