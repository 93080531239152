import { Router } from '@angular/router';
import { CurrentUser } from "vebto-client/auth/current-user";
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "@angular/router";
import * as i3 from "../auth/auth.module";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(currentUser, router) {
        this.currentUser = currentUser;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        return this.handle(state);
    };
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.handle(state);
    };
    AuthGuard.prototype.handle = function (state) {
        if (this.currentUser.isLoggedIn()) {
            return true;
        }
        this.currentUser.redirectUri = state.url;
        this.router.navigate(['/login']);
        return false;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.CurrentUser), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: i3.AuthModule });
    return AuthGuard;
}());
export { AuthGuard };
