/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-file-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "../file-dropzone/file-dropzone.directive";
import * as i5 from "../uploads.service";
import * as i6 from "@angular/common";
import * as i7 from "../../ui/map-to-iterable/map-to-iterable.pipe";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../ui/svg-icon/svg-icon.component.ngfactory";
import * as i10 from "../../ui/svg-icon/svg-icon.component";
import * as i11 from "../../config/settings.service";
import * as i12 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i13 from "@angular/material/button";
import * as i14 from "@angular/cdk/platform";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "./upload-file-modal.component";
var styles_UploadFileModalComponent = [i0.styles];
var RenderType_UploadFileModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_UploadFileModalComponent, data: {} });
export { RenderType_UploadFileModalComponent as RenderType_UploadFileModalComponent };
function View_UploadFileModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "accent"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef], { color: [0, "color"], value: [1, "value"], mode: [2, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "accent"; var currVal_3 = _co.uploadProgress; var currVal_4 = "determinate"; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).value; var currVal_1 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_UploadFileModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tab upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dropzone"], ["fileDropzone", ""], ["trans", ""]], null, [[null, "onUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpload" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i4.FileDropzoneDirective, [i1.ElementRef, i1.Renderer2, i5.Uploads], null, { onUpload: "onUpload" }), (_l()(), i1.ɵted(-1, null, ["Drop Files Here or Click to Upload"]))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_UploadFileModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "tab link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["for", "image-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File URL"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["id", "image-link"], ["type", "text"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setLinkModel($event.target["value"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["trans", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It must be direct link to a file. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Example: http://site.com/image.png"]))], null, null); }
function View_UploadFileModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadFileModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "errors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UploadFileModalComponent_5)), i1.ɵdid(2, 802816, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.errors)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UploadFileModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.MapToIterable, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["class", "mat-dialog-title mat-dialog-title"], ["mat-dialog-title", ""], ["trans", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i8.MatDialogTitle, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Upload File"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "close-button no-style icon-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svg-icon", [["name", "close"]], null, null, null, i9.View_SvgIconComponent_0, i9.RenderType_SvgIconComponent)), i1.ɵdid(7, 114688, null, 0, i10.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i11.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 16, "div", [["class", "mat-dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 14, "div", [["class", "upload-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "menu-item upload-tab-button no-style"], ["trans", ""]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveTab("upload") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "menu-item link-tab-button no-style"], ["trans", ""]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveTab("link") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Link"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFileModalComponent_1)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFileModalComponent_2)), i1.ɵdid(20, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFileModalComponent_3)), i1.ɵdid(22, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFileModalComponent_4)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 7, "div", [["class", "mat-dialog-actions mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(26, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "button cancel"], ["mat-button", ""], ["trans", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(28, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.Platform, i15.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "button primary submit-button"], ["color", "accent"], ["mat-raised-button", ""], ["trans", ""], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i1.ɵdid(31, 180224, null, 0, i13.MatButton, [i1.ElementRef, i14.Platform, i15.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Upload"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = "close"; _ck(_v, 7, 0, currVal_1); var currVal_4 = (_co.uploadProgress !== null); _ck(_v, 17, 0, currVal_4); var currVal_5 = (_co.activeTab === "upload"); _ck(_v, 20, 0, currVal_5); var currVal_6 = (_co.activeTab === "link"); _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.errors; _ck(_v, 24, 0, currVal_7); var currVal_10 = "accent"; _ck(_v, 31, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.activeTab === "upload"); _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.activeTab === "link"); _ck(_v, 14, 0, currVal_3); var currVal_8 = (i1.ɵnov(_v, 28).disabled || null); _ck(_v, 27, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 31).disabled || null); _ck(_v, 30, 0, currVal_9); }); }
export function View_UploadFileModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "upload-file-modal", [], null, null, null, View_UploadFileModalComponent_0, RenderType_UploadFileModalComponent)), i1.ɵdid(1, 49152, null, 0, i16.UploadFileModalComponent, [i5.Uploads, i11.Settings, i8.MatDialogRef, [2, i8.MAT_DIALOG_DATA]], null, null)], null, null); }
var UploadFileModalComponentNgFactory = i1.ɵccf("upload-file-modal", i16.UploadFileModalComponent, View_UploadFileModalComponent_Host_0, {}, {}, []);
export { UploadFileModalComponentNgFactory as UploadFileModalComponentNgFactory };
