/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-player-controls.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../player-controls/player-seekbar/player-seekbar.component.ngfactory";
import * as i3 from "../player-controls/player-seekbar/player-seekbar.component";
import * as i4 from "../formatted-duration.service";
import * as i5 from "../player.service";
import * as i6 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component.ngfactory";
import * as i7 from "../../../../vebto-client/core/ui/svg-icon/svg-icon.component";
import * as i8 from "../../../../vebto-client/core/config/settings.service";
import * as i9 from "../../artists/artists-links-list/artists-links-list.component.ngfactory";
import * as i10 from "../../artists/artists-links-list/artists-links-list.component";
import * as i11 from "../../web-player-urls.service";
import * as i12 from "../player-controls/main-playback-buttons/main-playback-buttons.component.ngfactory";
import * as i13 from "../player-controls/main-playback-buttons/main-playback-buttons.component";
import * as i14 from "@angular/common";
import * as i15 from "../../../../vebto-client/core/ui/custom-menu/custom-menu.component.ngfactory";
import * as i16 from "../../../../vebto-client/core/ui/custom-menu/custom-menu.component";
import * as i17 from "../../../../vebto-client/auth/current-user";
import * as i18 from "./mobile-player-controls.component";
import * as i19 from "../../fullscreen-overlay/fullscreen-overlay.service";
var styles_MobilePlayerControlsComponent = [i0.styles];
var RenderType_MobilePlayerControlsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MobilePlayerControlsComponent, data: {} });
export { RenderType_MobilePlayerControlsComponent as RenderType_MobilePlayerControlsComponent };
function View_MobilePlayerControlsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "player-seekbar", [], null, null, null, i2.View_PlayerSeekbarComponent_0, i2.RenderType_PlayerSeekbarComponent)), i1.ɵdid(2, 4374528, null, 0, i3.PlayerSeekbarComponent, [i1.ElementRef, i4.FormattedDuration, i5.Player, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "no-style maximize"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.overlay.maximize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "svg-icon", [["name", "keyboard-arrow-up"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(6, 114688, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i8.Settings], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "track-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "track-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "artists-links-list", [["class", "artist-name"]], null, null, null, i9.View_ArtistsLinksListComponent_0, i9.RenderType_ArtistsLinksListComponent)), i1.ɵdid(11, 49152, null, 0, i10.ArtistsLinksListComponent, [i11.WebPlayerUrls], { artists: [0, "artists"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "main-playback-buttons", [], null, null, null, i12.View_MainPlaybackButtonsComponent_0, i12.RenderType_MainPlaybackButtonsComponent)), i1.ɵdid(13, 49152, null, 0, i13.MainPlaybackButtonsComponent, [i5.Player], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "keyboard-arrow-up"; _ck(_v, 6, 0, currVal_0); var currVal_2 = _co.player.getCuedTrack().artists; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.player.getCuedTrack().name; _ck(_v, 9, 0, currVal_1); }); }
export function View_MobilePlayerControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobilePlayerControlsComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "custom-menu", [["class", "nav"], ["position", "mobile-bottom"]], [[2, "hidden", null]], null, null, i15.View_CustomMenuComponent_0, i15.RenderType_CustomMenuComponent)), i1.ɵdid(3, 245760, null, 0, i16.CustomMenuComponent, [i8.Settings, i17.CurrentUser], { position: [0, "position"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.player.cued(); _ck(_v, 1, 0, currVal_0); var currVal_2 = "mobile-bottom"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).shouldHide; _ck(_v, 2, 0, currVal_1); }); }
export function View_MobilePlayerControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-player-controls", [], null, null, null, View_MobilePlayerControlsComponent_0, RenderType_MobilePlayerControlsComponent)), i1.ɵdid(1, 49152, null, 0, i18.MobilePlayerControlsComponent, [i5.Player, i19.FullscreenOverlay], null, null)], null, null); }
var MobilePlayerControlsComponentNgFactory = i1.ɵccf("mobile-player-controls", i18.MobilePlayerControlsComponent, View_MobilePlayerControlsComponent_Host_0, {}, {}, []);
export { MobilePlayerControlsComponentNgFactory as MobilePlayerControlsComponentNgFactory };
