var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, of as observableOf } from "rxjs";
import { share } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { HttpErrorHandler } from "./errors/http-error-handler.service";
import { AppHttpClient } from "vebto-client/core/http/app-http-client.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./errors/http-error-handler.service";
import * as i3 from "./http.module";
var HttpCacheClient = /** @class */ (function (_super) {
    __extends(HttpCacheClient, _super);
    /**
     * HttpClient Constructor.
     */
    function HttpCacheClient(http, errorHandler) {
        var _this = _super.call(this, http, errorHandler) || this;
        _this.http = http;
        _this.errorHandler = errorHandler;
        /**
         * Http cache store.
         */
        _this.cache = {};
        return _this;
    }
    /**
     * Make new GET request or returned its cached response.
     */
    HttpCacheClient.prototype.getWithCache = function (url, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        var key = this.makeCacheKey(url, params);
        //this request is in progress and not yet resolved,
        //we can return same observable for all callers
        if (this.cache[key] instanceof Observable) {
            return this.cache[key];
            //this request is completed and cached, we can return cached response
        }
        else if (this.cache[key]) {
            return observableOf(this.cache[key]);
            //this request was not made yet, so we make a new one
        }
        else {
            var request = this.get(url, params).pipe(share());
            request.subscribe(function (data) { return _this.cache[key] = data; }, function () { });
            return this.cache[key] = request;
        }
    };
    /**
     * Clear cache on POST requests.
     */
    HttpCacheClient.prototype.post = function (url, params, headers) {
        this.clearCache();
        return _super.prototype.post.call(this, url, params);
    };
    /**
     * Clear cache on PUT requests.
     */
    HttpCacheClient.prototype.put = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        this.clearCache();
        return _super.prototype.put.call(this, url, params);
    };
    /**
     * Clear cache on DELETE requests.
     */
    HttpCacheClient.prototype.delete = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        this.clearCache();
        return _super.prototype.delete.call(this, url, params);
    };
    /**
     * Clear http cache for this service.
     */
    HttpCacheClient.prototype.clearCache = function () {
        this.cache = {};
    };
    /**
     * Create unique cache key for specified http request.
     */
    HttpCacheClient.prototype.makeCacheKey = function (url, params) {
        return url + JSON.stringify(params);
    };
    HttpCacheClient.ngInjectableDef = i0.defineInjectable({ factory: function HttpCacheClient_Factory() { return new HttpCacheClient(i0.inject(i1.HttpClient), i0.inject(i2.HttpErrorHandler)); }, token: HttpCacheClient, providedIn: i3.HttpModule });
    return HttpCacheClient;
}(AppHttpClient));
export { HttpCacheClient };
