/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "../../config/settings.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./custom-menu.component";
import * as i8 from "../../../auth/current-user";
var styles_CustomMenuComponent = [i0.styles];
var RenderType_CustomMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CustomMenuComponent, data: {} });
export { RenderType_CustomMenuComponent as RenderType_CustomMenuComponent };
function View_CustomMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "href", 4]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toSnakeCase(_v.parent.parent.context.$implicit.label); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.parent.context.$implicit.icon; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.action, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.parent.context.$implicit.label; _ck(_v, 4, 0, currVal_3); }); }
function View_CustomMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 671744, [[2, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_6)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.toSnakeCase(_v.parent.parent.context.$implicit.label); _ck(_v, 1, 0, currVal_2); var currVal_3 = ("/" + _v.parent.parent.context.$implicit.action); _ck(_v, 2, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.parent.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _v.parent.parent.context.$implicit.label; _ck(_v, 8, 0, currVal_6); }); }
function View_CustomMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer2, i4.Settings], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 671744, [[4, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_8)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.toSnakeCase(_v.parent.parent.context.$implicit.label); _ck(_v, 1, 0, currVal_2); var currVal_3 = ("/pages/" + _v.parent.parent.context.$implicit.action); _ck(_v, 2, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.parent.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _v.parent.parent.context.$implicit.label; _ck(_v, 8, 0, currVal_6); }); }
function View_CustomMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_7)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.type === "link"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.type === "route"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.type === "page"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_CustomMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "nav-item custom-menu-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShow(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomMenuComponent_1)), i1.ɵdid(1, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "custom-menu", [], [[2, "hidden", null]], null, null, View_CustomMenuComponent_0, RenderType_CustomMenuComponent)), i1.ɵdid(1, 245760, null, 0, i7.CustomMenuComponent, [i4.Settings, i8.CurrentUser], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).shouldHide; _ck(_v, 0, 0, currVal_0); }); }
var CustomMenuComponentNgFactory = i1.ɵccf("custom-menu", i7.CustomMenuComponent, View_CustomMenuComponent_Host_0, { position: "position" }, {}, ["*"]);
export { CustomMenuComponentNgFactory as CustomMenuComponentNgFactory };
