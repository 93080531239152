import { Router } from '@angular/router';
import { CurrentUser } from "vebto-client/auth/current-user";
import { AuthService } from "../auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "@angular/router";
import * as i3 from "../auth/auth.service";
import * as i4 from "../core/core.module";
var CheckPermissionsGuard = /** @class */ (function () {
    function CheckPermissionsGuard(currentUser, router, auth) {
        this.currentUser = currentUser;
        this.router = router;
        this.auth = auth;
    }
    CheckPermissionsGuard.prototype.canActivate = function (route, state) {
        return this.hasPermission(route, state);
    };
    CheckPermissionsGuard.prototype.canActivateChild = function (route, state) {
        return this.hasPermission(route, state);
    };
    CheckPermissionsGuard.prototype.hasPermission = function (route, state) {
        var hasPermission = true;
        if (route.data.permissions) {
            hasPermission = this.currentUser.hasPermissions(route.data.permissions);
        }
        //user can access this route, bail
        if (hasPermission)
            return true;
        //redirect to login page, if user is not logged in
        if (!this.currentUser.isLoggedIn()) {
            this.currentUser.redirectUri = state.url;
            this.router.navigate(['login']);
        }
        else {
            this.router.navigate([this.auth.getRedirectUri()]);
        }
        return hasPermission;
    };
    CheckPermissionsGuard.ngInjectableDef = i0.defineInjectable({ factory: function CheckPermissionsGuard_Factory() { return new CheckPermissionsGuard(i0.inject(i1.CurrentUser), i0.inject(i2.Router), i0.inject(i3.AuthService)); }, token: CheckPermissionsGuard, providedIn: i4.CoreModule });
    return CheckPermissionsGuard;
}());
export { CheckPermissionsGuard };
